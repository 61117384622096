import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { useQueryCache } from 'react-query';
import { ContentWrapper } from '..';
import SettingsEmail from './SettingsEmail';
import SettingsEmailConfirm from './SettingsEmailConfirm';
import SettingsPassword from './SettingsPassword';
import { Item } from './Settings.styled';

export default function Settings() {
  const user = useQueryCache().getQueryData<Models.User>('user');

  return (
    <ContentWrapper>
      <Item>
        Логин: <b>{user.username}</b>
      </Item>

      <Item>
        E-mail: <b>{user.email}</b>

        <div>
          <Switch>
            <Route path="/settings/change-email/:id" component={SettingsEmailConfirm} />
            <Route component={SettingsEmail} />
          </Switch>
        </div>
      </Item>

      <Item>
        Пароль: <b>*****</b>

        <div>
          <SettingsPassword />
        </div>
      </Item>
    </ContentWrapper>
  );
}
