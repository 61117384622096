import React, { useCallback } from 'react';
import { useMutation } from 'react-query';
import { Button } from 'antd';
import { CheckCircleOutlined, FileTextOutlined } from '@ant-design/icons';
import srv from '../../../../services';
import { Message, Actions, Warning } from './Print.styled';

interface Props {
  values: Record<string, any>;
}

export default function Print({ values }: Props) {
  const { isLoading, onLoad } = useApplicationFile(values);

  return (
    <>
      <Message>
        <CheckCircleOutlined />
        Заявление успешно сформировано
      </Message>

      <Actions>
        <Button
          type="primary"
          size="large"
          icon={<FileTextOutlined />}
          loading={isLoading}
          onClick={onLoad}
        >
          Печатная форма заявления
        </Button>
      </Actions>

      <Warning>
        <b>Внимание! </b>
        После закрытия этого окна открыть печатную форму будет невозможно!
      </Warning>
    </>
  );
}

function useApplicationFile(values) {
  const [request, { isLoading }] = useMutation(srv.openApplicationDocument);
  
  const onLoad = useCallback(() => {
    request(values);
  }, [values, request]);

  return { isLoading, onLoad };
}
