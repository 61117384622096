import styled from 'styled-components';
import { BaseForm as Form } from '../Auth.styled';

export const BaseForm = styled(Form)`
  h2 {
    margin-top: 1.5rem;
    text-align: center;
  }
`;

export const Success = styled.div`
  margin: 2rem auto;
  max-width: 23rem;

  .ant-btn {
    display: block;
    margin: 1rem auto;
  }
`;
