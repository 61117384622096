import React, { useEffect } from 'react';
import { useMutation, useQueryCache, useQuery } from 'react-query';
import { Form, Input, Spin, Alert } from 'antd';
import { useDebouncedCallback } from 'use-debounce';
import srv from '../../../../services';
import { Loading, Info } from './Applicant.styled';

export default function Applicant() {
  const { applicant, isLoading, error, onChange } = useApplicant();

  const applicantFields = [
    { name: 'name', label: '' },
    { name: 'city', label: 'Город' },
    { name: 'address', label: 'Адрес' },
    { name: 'phone', label: 'Телефон' },
    { name: 'email', label: 'E-mail' },
  ];

  return (
    <>
      <Form.Item label="ИНН">
        <Input
          autoFocus
          disabled={isLoading}
          onChange={e => onChange(e.target.value)}
        />
      </Form.Item>

      {(() => {
        if (isLoading) return (
          <Loading>
            <Spin size="small" /> Запрос информации о компании...
          </Loading>
        );

        if (error) return (
          <Alert
            type="error"
            showIcon
            message="Информация о компании не найдена"
          />
        );

        if (applicant) return (
          <Info>
            {applicantFields.map(field => {
              if (!applicant[field.name]) return null;
              return (
                <div key={field.name}>
                  <b>{field.label}</b> {applicant[field.name]}
                </div>
              );
            })}
          </Info>
        );

        return null;
      })()}
    </>
  );
}

function useApplicant() {
  const queryCache = useQueryCache();

  const { data: applicant, clear } = useQuery('foundApplicant');

  const [search, { isLoading, error }] = useMutation(srv.searchApplicant, {
    onSuccess: data => queryCache.setQueryData('foundApplicant', data),
    onError: () => queryCache.setQueryData('foundApplicant', null),
  });

  const searchDebounced = useDebouncedCallback(search, 500);

  const onChange = (inn: string) => {
    if (inn.length > 9) {
      searchDebounced.callback(inn);
    } else if (!inn.length) {
      queryCache.setQueryData('foundApplicant', null);
    }
  };

  useEffect(() => () => {
    clear();
  }, [clear]);

  return { applicant, isLoading, error, onChange };
}
