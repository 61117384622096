import React, { useEffect } from 'react';
import { Form, Button } from 'antd';
import { FormListFieldData } from 'antd/lib/form/FormList';
import { PlusOutlined } from '@ant-design/icons';
import moment from 'moment';
import Person from './Person';
import { Guardians } from './Debtor.styled';

interface Props {
  removable?: boolean;
  onRemove: (id: number) => void;
}

export default function Debtor({
  name, removable = true, onRemove, ...restProps
}: FormListFieldData & Props) {
  return (
    <div>
      <Person name={name} onRemove={onRemove} removable={removable} {...restProps} />

      <Form.Item 
        shouldUpdate={(prev, next) => (
          prev.respondents?.[name]?.date_of_birth !== next.respondents?.[name]?.date_of_birth
        )}
        noStyle
      >
        {({ getFieldValue: val }) => (
          <Form.Item name={[name, 'underage']} hidden>
            <Underage birthDay={val('respondents')?.[name]?.date_of_birth} />
          </Form.Item>
        )}
      </Form.Item>

      <Form.Item
        shouldUpdate={(prev, next) => (
          prev.respondents?.[name]?.underage !== next.respondents?.[name]?.underage
        )}
        noStyle
      >
        {({ getFieldValue: val }) => {
          if (!val('respondents')?.[name]?.underage) return null;

          return (
            <Form.List name={[name, 'guardian']}>
              {(fields, { add, remove }) => (
                <Guardians>
                  <h4>Законные представители</h4>

                  {fields.map(field => (
                    <Person {...field} removable={fields.length > 1} onRemove={remove} />
                  ))}

                  <Button icon={<PlusOutlined />} onClick={() => add()}>
                    Добавить законного представителя
                  </Button>
                </Guardians>
              )}
            </Form.List>
          );
        }}
      </Form.Item>
    </div>
  );
}

function Underage({ birthDay, onChange }: any) {
  useEffect(() => {
    const isUnderage = !!(birthDay && moment().diff(birthDay, 'years') < 18);
    onChange(isUnderage);
  }, [birthDay, onChange]);

  return null;
}
