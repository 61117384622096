import React from 'react';
import { useQuery } from 'react-query';
import { Form, Select, DatePicker, Button, Popover } from 'antd';
import { FormListFieldData } from 'antd/lib/form/FormList';
import srv from '../../../../services';
import { MoneyInput } from '../../..';
import { RemoveListItem, PeriodSum } from '../ApplicationForm.styled';
import { Wrapper, ServiceSelect, Aside } from './Service.styled';
import NewServiceForm from './NewServiceForm';

interface Props {
  removable?: boolean;
  onRemove: (id: number) => void;
}

export default function Service({ name, removable = true, onRemove }: FormListFieldData & Props) {
  const { data: serviceTypes, isFetching, updatedAt, refetch } = useQuery('serviceTypes', srv.getServiceTypes);

  return (
    <Wrapper>
      <div>
        <ServiceSelect>
          <Form.Item name={[name, 'serviceCode']} label="Услуга" rules={[{ required: true }]}>
            <Select
              disabled={isFetching}
              showSearch
              optionFilterProp="children"
              getPopupContainer={e => e.parentElement}
            >
              {serviceTypes.map(item => (
                <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item dependencies={['services']} noStyle>
            {form => {
              const services = form.getFieldValue('services');
              return (
                <Popover
                  key={String(updatedAt)}
                  content={(
                    <NewServiceForm
                      onSuccess={serviceCode => form.setFieldsValue({
                        services: [
                          ...services.slice(0, name),
                          { ...services[name], serviceCode },
                          ...services.slice(name + 1),
                        ]
                      })} 
                      onCancel={refetch}
                    />
                  )}
                  destroyTooltipOnHide
                  getPopupContainer={e => e.parentElement}
                  trigger="click"
                >
                  <Button>
                    Добавить новую услугу
                  </Button>
                </Popover>
              );
            }}
          </Form.Item>
        </ServiceSelect>

        <PeriodSum>
          <Form.Item name={[name, 'period']} label="Период задолженности" rules={[{ required: true }]}>
            <DatePicker.RangePicker
              allowClear={false}
              format="DD.MM.YYYY"
              getPopupContainer={e => e.parentElement}
            />
          </Form.Item>

          <Form.Item name={[name, 'amount']} label="Сумма задолженности" rules={[{ required: true }]}>
            <MoneyInput />
          </Form.Item>
        </PeriodSum>
      </div>

      {removable && (
        <Aside>
          <RemoveListItem
            title="Удалить"
            onClick={() => onRemove(name)}
          />
        </Aside>
      )}
    </Wrapper>
  );
}
