import React, { useState, useEffect, useMemo } from 'react';
import { useQuery } from 'react-query';
import { useDebounce } from 'use-debounce';
import { Form, Input, AutoComplete } from 'antd';
import srv from '../../../../../services';

interface Props {
  value?: {
    street_fias_id: string;
    value: string;
  };
  onChange?: (val) => void
}

export default function StreetAutocomplete({ value, onChange }: Props) {
  const { query, suggestions, onQueryChange } = useAutocomplete({ value, onChange });

  return (
      <Form.Item label="Район, нас.пункт, улица" required>
        <AutoComplete
          defaultActiveFirstOption={false}
          options={suggestions}
          value={query}
          onChange={onQueryChange}
        >
          <Input />
        </AutoComplete>
      </Form.Item>
  );
}

function useAutocomplete({value, onChange }: Props) {

  const [query, onQueryChange] = useState(null);

  const [debouncedQuery] = useDebounce({query, street: true}, 300);

  const { data: suggestions } = useQuery(['suggestions', debouncedQuery], srv.getSuggestions);
  
  const address = useMemo(() => {
    if (query === null) return null;
    return ['street_fias_id'].reduce((acc, field) => {
      if (!suggestions?.[0]?.data) return acc;
      if (Array.isArray(field)) {
        return {
          ...acc,
          [field[0]]: suggestions[0].data[field[0]] || suggestions[0].data[field[1]],
        };
      }
      return {
        ...acc,
        [field]: suggestions[0].data[field],
      };
    }, {value: suggestions?.[0]?.value});
  }, [query, suggestions]);

  useEffect(() => {
    if (address !== null) onChange(address);
  }, [address, onChange]);

  return { query: query || value?.value, suggestions, onQueryChange };
}
