import axios from 'axios';
import queryCache from '../queryCache';

export async function getApplications(page: number, limit: number) {
  const res = await axios.get<Models.Paginated<Models.Application>>('lawsuit/list', {
    params: { limit, offset: limit * (page - 1) },
  });
  return res.data;
}

export async function saveApplication(data) {
  const user = queryCache.getQueryData<Models.User>('user');
  const applicant = queryCache.getQueryData<Models.Applicant>('applicant');
  const courts = queryCache.getQueryData<Models.Court[]>('courts');
  const serviceTypes = queryCache.getQueryData<Models.Reference[]>('serviceTypes');
  const expenseTypes = queryCache.getQueryData<Models.Reference[]>('expenseTypes');

  const payload = {
    ...data,
    ...(!data.address ? {} : {
      ...data.address,
      flat: data.room ? `${data.address.flat}, комн. ${data.room}` : data.address.flat,
    }),
    address: undefined,
    applicant,
    inn: user.inn,
    court: courts.find(c => c.judgeDeptId === data.courtId)?.name,
    period: undefined,
    period_start: data.period[0],
    period_end: data.period[1],
    period_peni: undefined,
    period_start_peni: data.period_peni ? data.period_peni[0] : undefined,
    period_end_peni: data.period_peni ? data.period_peni[1] : undefined,
    respondents: data.respondents.map(resp => ({
      ...resp,
      guardian: resp.underage ? resp.guardian : null,
    })),
    services: data.services.map(service => ({
      ...service,
      name: serviceTypes.find(s => s.id === service.serviceCode)?.name,
      period: undefined,
      period_start: service.period[0],
      period_end: service.period[1],
    })),
    extraExpenses: !data.extraExpenses?.length ? null : data.extraExpenses.map(expense => ({
      ...expense,
      name: expenseTypes.find(e => e.id === expense.extraExpenseTypeId)?.name,
    })),
  };

  const res = await axios.post('lawsuit/publish', payload);

  return {
    lawsuitId: res.data?.lawsuitId,
    ...payload,
  };
}

export async function openApplicationDocument(data) {
  const res = await axios.post('doc/getlawsuitdocument', data, {
    responseType: 'blob',
  });

  const blobURL = URL.createObjectURL(res.data);
  window.open(blobURL);
}

export async function getServiceTypes(): Promise<Models.Reference[]> {
  const res = await axios.get('dict/service/list');
  return res.data?.items ?? [];
}

export async function saveServiceType(payload): Promise<Models.Reference> {
  const serviceTypes = queryCache.getQueryData<Models.Reference[]>('serviceTypes');

  if (serviceTypes.some(s => s.name.toLowerCase() === payload.name.toLowerCase())) {
    return Promise.reject('Услуга с таким наименованием существует');
  }

  const res = await axios.post('dict/service', payload);
  return res.data;
}

export async function getExpenseTypes(): Promise<Models.Reference[]> {
  const res = await axios.get('dict/extraexpense/list');
  return res.data;
}

export async function getTollage({ amount, fine, expenseAmount }): Promise<number> {
  const res = await axios.post('statefee/getstatefee', {
    basicdebt: amount,
    fine,
    expenseAmount,
  });
  return res.data?.statefee;
}

export async function reestrUploadFile(data): Promise<string> {
  const res = await axios.post('/uploadreestr', data, {headers: { 'Content-Type': 'multipart/form-data' }});
  return res.statusText;
}