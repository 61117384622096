import styled from 'styled-components';
import * as antd from 'antd';
import Form from '../BaseForm';

export const Spin = styled(antd.Spin)`
  display: block;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -16px;
  margin-top: -16px;
`;

export const Wrapper = styled.div`
  min-height: 100vh;
  padding-bottom: 3rem;
`;

export const Header = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 3rem;
  margin-bottom: 0.7rem;
  font-size: 1.1em;
  background: #061f36;
  
  a {
    color: #fdcca2;
  }

  img {
    display: block;
    height: 100%;
    margin-right: 0.5rem;
    margin-left: -0.5rem;
  }
`;

export const Tabs = styled(antd.Tabs)`
  .ant-tabs-nav {
    @media (min-width: 640px) {
      padding-left: 1.5rem;
    }
  }

  .ant-tabs-tabpane {
    display: flex;
    justify-content: center;
    margin: 0.2rem auto;
    max-width: 25rem;
  }
`;

export const BaseForm = styled(Form)`
  margin: auto;
  max-width: 23rem;

  .ant-form-item {
    margin-bottom: 0.7rem;
  }

  .ant-alert-error {
    margin: 1rem 0 0.23rem;
  }

  button[type="submit"] {
    margin-top: 0.77rem;
    width: 100%;
  }
`;

export const JurisdictionWrapper = styled.div`
  width: 33rem;
  max-width: 100%;
  margin: 2rem auto;

  h1 {
    font-size: 1.5em;
    margin-bottom: 1rem;
    text-align: center;
  }

  .ant-form-item {
    margin-bottom: 0.8rem;
  }

  button {
    width: 100%;
  }
`;
