import axios, { AxiosResponse } from 'axios';
import jwtDecode from 'jwt-decode';

export async function getCurrentUser(): Promise<Models.User> {
  const token = localStorage.getItem('token');
  if (!token) return null;

  const { unique_name } = jwtDecode(token);

  const res = await axios.get(`user/${unique_name}`);
  return res.data;
}

export async function authUser(payload): Promise<Models.User> {
  const res = await axios.post('identity/authenticate', payload);
  if (res.data?.accessToken) {
    localStorage.setItem('token', res.data.accessToken);
  }
  const user = await getCurrentUser();
  return user;
}

export async function regUser(payload) {
  const res = await axios.post('user/register', payload);
  return res.statusText;
}

export async function changeUserPassword({ id, ...payload }) {
  const res = await axios.post(`user/changePwd/${id}`, payload);
  return res.statusText;
}

export async function changeUserEmail(payload) {
  const res = await axios.post('identity/changeemailbyuser', payload);
  return res.statusText;
}

export async function changeUserEmailConfirm(id) {
  const res = await axios.get(`identity/changeemailapprove/${id}`);
  return res.statusText;
}

export async function restoreUser(query: string) {
  let res: AxiosResponse;
  try {
    res = await axios.get(`identity/loginreminderpwdbyname/${query}`);
  } catch (error) {
    if (query.includes('@')) {
      res = await axios.get(`identity/loginreminderloginpwdbymail/${query}`);
    } else {
      return Promise.reject(error);
    }
  }
  return res.statusText;
}

export async function restoreUserConfirm({ id, password }) {
  const res = await axios.post('identity/loginreminderpwdapprove', {
    approveGuid: id,
    newPassword: password,
  });
  return res.statusText;
}
