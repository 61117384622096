import React, { useCallback, useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { Button, Form, Input, Alert, Select, Table } from 'antd';
import srv from '../../../../services';
import { BaseForm } from '../../..';
import { Actions } from '../JurisdictionForm.styled';
import StreetAutocomplete from './StreetAutocomplete';

interface Props {
  recordId?: string;
  courtId?: number;
  onFinish: () => void;
}

export default function JurisdictionAddAddressesForm({ onFinish, ...restProps }: Props) {
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const { isSearching, searchingError, data, onClear, onSearch } = useCheck(setSelectedRowKeys);
  const { isSubmiting, submitingError, onSubmitForm } = useSave( {onFinish, ...restProps});


  if (data && data.length > 0){
    const columns = [
      {
        title: `${selectedRowKeys.length} из ${data.length}`,
        dataIndex: 'fiasFullAddress',
        key: 'fiasFullAddress',
      }
     ];

    const rowSelection = {
        selectedRowKeys,
        onChange: setSelectedRowKeys,
    };

    return (
        <BaseForm
      size="small"
      scrollToFirstError
      onFinish = {() => onSubmitForm(selectedRowKeys)}
    >
      <header>
        <h2>
            Добавление адресов в судебный участок 
        </h2>
      </header>
        <Table
        loading={isSearching}
        columns={columns}
        dataSource={data}
        rowKey={row => row.houseId}
        rowSelection={rowSelection}
        />
        {!!submitingError && <Alert type="error" showIcon message={submitingError} />}

        <Actions>
        <div>
            <Button htmlType="submit" type="primary" size="middle" loading={isSubmiting}>
            Сохранить
            </Button>

            <Button size="middle" onClick={onClear}>
            Отмена
            </Button>
        </div>

        </Actions>
        </BaseForm>
    );
  }

  return (
    <BaseForm
      size="small"
      scrollToFirstError
      onFinish = {onSearch}
    >
      <header>
        <h2>
            Добавление адресов в судебный участок 
        </h2>
      </header>

      <Form.Item name="streetSelected">
          <StreetAutocomplete />
      </Form.Item>

      <Form.Item name={["housesQuery", "query"]} label="Дома" required>
        <Input />
      </Form.Item>

      <Form.Item name={["housesQuery", "housesQueryType"]} initialValue = "0" label="Чётность">
        <Select 
            options={[
                { value: '0', label: 'Все'},
                { value: '1', label: 'Чётные' },
                { value: '2', label: 'Нечётные' },
            ]}
        />
      </Form.Item>

      <Form.Item name={["housesQueryExclude", "query"]} label="Исключения">
        <Input />
      </Form.Item>

      {!!searchingError && <Alert type="error" showIcon message={searchingError} />}

      <Actions>
        <div>
          <Button htmlType="submit" type="primary" size="middle" loading={isSearching}>
            Найти
          </Button>

          <Button size="middle" onClick={onFinish}>
            Отмена
          </Button>
        </div>

      </Actions>
    </BaseForm>
  );
}

function useSave({courtId, onFinish }: Props) {
 
  
    const [link, { isLoading, isSuccess, error, reset }] = useMutation(srv.link2judgedept);
    const onSubmitForm = useCallback((housekeys) => {
              link({ housekeys, judgeDeptId: courtId });
    }, [link, courtId]);
  
    useEffect(() => {
      if (isSuccess) {
        onFinish();
        reset();
      }
    }, [isSuccess, onFinish, reset]);
    return { isSubmiting: isLoading, submitingError: error, onSubmitForm};
  }

function useCheck(setSelectedRowKeys) {
  const [data, setData] = useState([]);


  const [search, { isLoading, error }] = useMutation(srv.searchAddress,{
    onSuccess: (data) => {
        setData(data.items);
        setSelectedRowKeys(data.items.map((item) => item.houseId));
    }
  });

  const onSearch = useCallback((values) => {
    values = { street_fias_id: values['streetSelected']?.['street_fias_id'], ...values}
    search(values);
  }, [search]);

  const onClear = useCallback(() => {
    setData([]);
    setSelectedRowKeys([]);
  }, [setSelectedRowKeys]);

  return { isSearching: isLoading, searchingError: error, data, onClear, onSearch };
}