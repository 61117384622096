import React, { useCallback, useEffect, useState } from 'react';
import { useMutation, useQueryCache } from 'react-query';
import { Form, Input, Button, Alert } from 'antd';
import srv from '../../../services';
import { BaseForm } from '../..';

export default function SettingsEmail() {
  const { isEditing, isLoading, isSuccess, error, message, onToggleForm, ...formProps } = useEmail();

  if (!isEditing) return (
    <>
      {isSuccess && <>
        <Alert type="success" message={message} /><br />
      </>}

      <Button type="primary" onClick={onToggleForm}>
        Изменить e-mail
      </Button>
    </>
  );

  return (
    <BaseForm
      layout="vertical"
      requiredMark={false}
      {...formProps}
    >
      <Form.Item name="password" label="Пароль" rules={[{ required: true }]}>
        <Input.Password autoComplete="new-password" />
      </Form.Item>

      <Form.Item name="newEmail" label="Новый e-mail" rules={[{ required: true, type: 'email' }]}>
        <Input />
      </Form.Item>

      {!!error && <Alert type="error" showIcon message={error} />}

      <Button htmlType="submit" type="primary" loading={isLoading}>
        Сохранить e-mail
      </Button>

      <Button onClick={onToggleForm}>
        Отмена
      </Button>
    </BaseForm>
  );
}

function useEmail() {
  const [isEditing, setEditing] = useState(false);

  const [form] = Form.useForm();

  const user = useQueryCache().getQueryData<Models.User>('user');

  const [changeEmail, {
    data: message , isLoading, isSuccess, error, reset
  }] = useMutation(srv.changeUserEmail);

  useEffect(() => {
    if (isSuccess) {
      form.resetFields();
      setEditing(false);
    }
  }, [isSuccess, form]);

  const onToggleForm = useCallback(() => {
    setEditing(prev => !prev);
    form.resetFields();
    reset();
  }, [form, reset]);

  const onFinish = useCallback((values) => {
    changeEmail({ userId: user.id, ...values });
  }, [changeEmail, user]);

  const onValuesChange = useCallback(() => {
    if (error) reset();
  }, [error, reset]);

  return {
    form, isEditing, isLoading, isSuccess, error, message, onToggleForm, onValuesChange, onFinish
  };
}
