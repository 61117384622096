import React, { useCallback, useEffect, useMemo } from 'react';
import { useMutation, useQueryCache, useQuery } from 'react-query';
import { Form, Button } from 'antd';
import srv from '../../../services';
import { BaseForm } from '../..';
import { Result } from './JurisdictionSearch.styled';
import Address from './JurisdictionAddressAutocomplete/JurisdictionAddressAutocomplete';

export default function JurisdictionSearch() {
  const { court, isLoading, isIdle, onSearch } = useCourtSearch();

  return (
    <BaseForm
      onFinish={onSearch}
    >
      <Form.Item name="address">
          <Address />
      </Form.Item>

      <Button
        type="primary"
        htmlType="submit"
        loading={isLoading}
      >
        Определить судебный участок
      </Button>

      <Result>
        {(() => {
          if (court) return (
            <>
              <h3>{court.name}</h3>

              <div>{court.address}</div>
            </>
          );

          if (!isIdle) return 'Участок не определен';

          return null;
        })()}
      </Result>
    </BaseForm>
  );
}

function useCourtSearch() {
  const queryCache = useQueryCache();

  const { data: courts } = useQuery<Models.Court[]>('courts', srv.getCourts);
  const { data: foundCourt, clear } = useQuery<Partial<Models.Court>>('foundCourt');

  const court = useMemo(() => {
    if (!foundCourt || !courts) return null;
    return courts.find(c => c.judgeDeptId === foundCourt.judgeDeptId);
  }, [courts, foundCourt]);

  const [request, { isLoading, isIdle, reset }] = useMutation(srv.searchCourt, {
    onSuccess: data => queryCache.setQueryData('foundCourt', data),
    onError: () => queryCache.setQueryData('foundCourts', null),
  });

  const onSearch = useCallback((values) => {
    clear();
    request(values.address);
  }, [clear, request]);

  useEffect(() => () => {
    clear();
    reset();
  }, [clear, reset]);

  return { court, isLoading, isIdle, onSearch };
}
