import React, { useCallback } from 'react';
import { useMutation, useQueryCache } from 'react-query';
import { Form, Input, Button, Alert } from 'antd';
import srv from '../../../services';
import { BaseForm } from '../Auth.styled';

export default function SignIn() {
  const { isLoading, error, ...formProps } = useAuth();

  return (
    <BaseForm
      layout="vertical"
      requiredMark={false}
      {...formProps}
    >
      <Form.Item name="username" label="Логин" rules={[{ required: true }]}>
        <Input autoFocus />
      </Form.Item>

      <Form.Item name="password" label="Пароль" rules={[{ required: true }]}>
        <Input.Password />
      </Form.Item>

      {!!error && <Alert type="error" showIcon message={error} />}

      <Button htmlType="submit" type="primary" loading={isLoading}>
        Войти
      </Button>
    </BaseForm>
  );
}

function useAuth() {
  const queryCache = useQueryCache();
  const [authenticate, { isLoading, error, reset }] = useMutation(srv.authUser, {
    onSuccess: data => queryCache.setQueryData('user', data),
  });

  const onFinish = useCallback((values) => {
    authenticate(values);
  }, [authenticate]);

  const onValuesChange = useCallback(() => {
    if (error) reset();
  }, [error, reset]);

  return { isLoading, error, onValuesChange, onFinish };
}
