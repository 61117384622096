import React, { useCallback } from 'react';
import { useParams } from 'react-router';
import { useMutation } from 'react-query';
import { Form, Input, Button, Alert } from 'antd';
import { LoginOutlined } from '@ant-design/icons';
import { FormInstance, RuleObject } from 'antd/lib/form';
import srv from '../../../services';
import { BaseForm, Success } from './RestorePasswordConfirm.styled';

export default function RestorePasswordConfirm() {
  const { isLoading, message, error, validatePasswordConfirm, ...formProps } = useResetPassword();

  if (message) return (
    <Success>
      <Alert type="success" message={message} />

      <Button type="primary" icon={<LoginOutlined />} href="/">
        Войти на сайт
      </Button>
    </Success>
  );

  return (
    <BaseForm
      layout="vertical"
      requiredMark={false}
      {...formProps}
    >
      <h2>Восстановление пароля</h2>

      <Form.Item name="password" label="Новый пароль" rules={[{ required: true }]}>
        <Input.Password autoFocus />
      </Form.Item>

      <Form.Item
        name="password_confirm"
        label="Подтверждение нового пароля"
        dependencies={['password']}
        rules={[{ required: true }, validatePasswordConfirm]}
      >
        <Input.Password />
      </Form.Item>

      {!!error && <Alert type="error" showIcon message={error} />}

      <Button htmlType="submit" type="primary" loading={isLoading}>
        Сохранить
      </Button>
    </BaseForm>
  );
}

function useResetPassword() {
  const { id } = useParams();
  const [confirm, { isLoading, error, data: message, reset }] = useMutation(srv.restoreUserConfirm);

  const validatePasswordConfirm = useCallback(({ getFieldValue }: FormInstance): RuleObject => ({
    validator(_, value) {
      if (!value || value === getFieldValue('password')) {
        return Promise.resolve();
      }
      return Promise.reject('Пароль и подтверждение не совпадают');
    },
  }), []);

  const onFinish = useCallback((values) => {
    confirm({ ...values, id });
  }, [id, confirm]);

  const onValuesChange = useCallback(() => {
    if (error) reset();
  }, [error, reset]);

  return {
    isLoading, error, message, validatePasswordConfirm, onValuesChange, onFinish,
  };
}
