import styled from 'styled-components';
import * as antd from 'antd';

export const Modal = styled(antd.Modal)`
  top: 1.1rem!important;
  width: 820px!important;
  max-width: calc(100% - 1rem);

  form footer {
    justify-content: center;
  }
`;
