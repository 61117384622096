import React from 'react';
import { InputNumber } from './MoneyInput.styled';
import { InputNumberProps } from 'antd/lib/input-number';

interface Props {
  id?: string;
  value?: number;
  onChange?: (val: number) => void
}

export default function MoneyInput({ id, value, onChange, ...restProps }: Props & InputNumberProps) {
  return (
    <>
      <InputNumber
        key={id}
        id={id}
        decimalSeparator="."
        formatter={(val: string): string => {
          const dotIndex = val.indexOf('.');
          return `${
            (dotIndex > 0 ? val.substr(0, dotIndex) : val)
              .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
          }${
            (dotIndex > 0 ? val.substr(dotIndex, val.length) : '')
          }`;
        }}
        min={0}
        precision={2}
        value={value}
        onChange={onChange}
        {...restProps}
      />
      ₽
    </>
  );
}
