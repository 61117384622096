import styled from 'styled-components';

export const Item = styled.div`
  margin-bottom: 1.2rem;

  b {
    font-size: 1.1em;
    font-weight: 500;
  }

  button {
    margin-top: 0.5rem;

    &[type="submit"] {
      margin-right: 0.4rem;
    }
  }

  form {
    max-width: 20rem;
    padding: 0.5rem 0;
    margin-top: 0.5rem;
    border-top: 1px solid #eaeaea;
  }

  .ant-alert-success {
    display: inline-block;
    margin-top: 0.43rem;
  }
`;
