import React, { useCallback } from 'react';
import { useMutation } from 'react-query';
import { Form, Input, Button, Alert } from 'antd';
import srv from '../../../services';
import { BaseForm } from '../Auth.styled';

export default function RestorePassword() {
  const { isLoading, message, error, ...formProps } = useResetPassword();

  if (message) return (
    <Alert type="success" message={message} />
  );

  return (
    <BaseForm
      layout="vertical"
      requiredMark={false}
      {...formProps}
    >
      <Form.Item name="query" label="Логин или E-mail" rules={[{ required: true }]}>
        <Input autoFocus />
      </Form.Item>

      {!!error && <Alert type="error" showIcon message={error} />}

      <Button htmlType="submit" type="primary" loading={isLoading}>
        Восстановить пароль
      </Button>
    </BaseForm>
  );
}

function useResetPassword() {
  const [restore, { isLoading, error, data: message, reset }] = useMutation(srv.restoreUser);

  const onFinish = useCallback(({ query }) => {
    restore(query);
  }, [restore]);

  const onValuesChange = useCallback(() => {
    if (error) reset();
  }, [error, reset]);

  return { isLoading, message, error, onValuesChange, onFinish };
}
