import axios from 'axios';
import queryCache from '../queryCache';

export async function getApplicant(): Promise<Models.Applicant> {
  const user = queryCache.getQueryData<Models.User>('user');
  if (!user.roles.some(r => r.roleName === 'Applicants')) return null;

  const res = await axios.get(`applicant/getbyinn/${user.inn}`);
  return res.data;
}

export async function searchApplicant(inn): Promise<Models.Applicant> {
  const res = await axios.get(`fns/${inn}`);
  return res.data;
}

export async function updateApplicant(payload): Promise<Models.Applicant> {
  const res = await axios.post('applicant', payload);
  return res.data;
}
