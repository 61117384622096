import React from 'react';
import { useQuery } from 'react-query';
import { Form, Select } from 'antd';
import { FormListFieldData } from 'antd/lib/form/FormList';
import srv from '../../../../services';
import { MoneyInput } from '../../..';
import { RemoveListItem } from '../ApplicationForm.styled';
import { Wrapper, Fields, Aside } from './Expense.styled';

interface Props {
  onRemove: (id: number) => void;
}

export default function Expense({ name, onRemove }: FormListFieldData & Props) {
  const { data: expenseTypes } = useQuery('expenseTypes', srv.getExpenseTypes);

  return (
    <Wrapper>
      <Fields>
        <Form.Item name={[name, 'extraExpenseTypeId']} label="Тип расходов" rules={[{ required: true }]}>
          <Select
            showSearch
            optionFilterProp="children"
            getPopupContainer={e => e.parentElement}
          >
            {expenseTypes.map(item => (
              <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item name={[name, 'amount']} label="Сумма расходов" rules={[{ required: true }]}>
          <MoneyInput />
        </Form.Item>
      </Fields>

      <Aside>
        <RemoveListItem
          title="Удалить"
          onClick={() => onRemove(name)}
        />
      </Aside>
    </Wrapper>
  );
}
