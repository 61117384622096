import styled from 'styled-components';
import * as antd from 'antd';

export const Table = styled(antd.Table)`
  margin-left: -2rem;

  * {
    background-color: transparent;
  }
`;
