import axios from 'axios';
import inside from 'point-in-polygon';
import queryCache from '../queryCache';

export async function getCourtAddresses(court?: number): Promise<Models.Address[]> {
  if (!court) return [];
  const res = await axios.get(`house/getbyjudgedeptid/${court}`);
  return res.data?.items ?? [];
}

export async function getAddress(id): Promise<Models.Address> {
  if (!id) return null;
  const res = await axios.get(`house/${id}`);
  return res.data;
}

export async function searchAddress(data: Partial<Models.AddressSet>): Promise<Models.Paginated<Models.SearchedAddress>> {
  const res = await axios.post('house/searchindadata', data);
  return res.data;
}

export async function link2judgedept(data){
  const res = await axios.post('house/link2judgedept', data);
  return res.data;
}


export async function saveAddress(data: Partial<Models.Address>) {
  const res = await axios.post('house', data);
  return res.data;
}

export async function removeAddress(id) {
  const res = await axios.delete(`house/${id}`);
  return res.data;
}

export async function getCourts(): Promise<Models.Court[]> {
  const res = await axios.get('judgedept/list');
  return res.data.items;
}

export async function searchCourt(payload): Promise<Partial<Models.Court>> {
  const res = await axios.post('house/search', payload);
  return {
    ...res.data,
    name: res.data.judgeDeptName,
  };
}

export async function getAddressByCoords(coords: [number, number]): Promise<string> {
  if (!coords) return null;
  await new Promise(rs => setTimeout(rs, 500));
  return 'Найденный адрес';
}

export async function getCoordsByAddress(query): Promise<[number, number]> {
  if (!query) return null;

  const { data } = await axios.post('geocoder/getCacheData', {
    url: 'https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address',
    restrict_value: true,
    query,
  });

  if (!data?.suggestions?.[0]?.data.geo_lat) return null;

  const coords: [number, number] = [
    Number(data.suggestions[0].data.geo_lat),
    Number(data.suggestions[0].data.geo_lon),
  ];

  // coords of city center
  if (coords[0] === 57.1529744 && coords[1] === 65.5344099) return null;
  return coords;
}

export async function updateAddressesCoords(addresses: Models.Address[]) {
  const addressesCoords = queryCache.getQueryData<Record<number, [number, number]>>('addressesCoords');

  await axios.all(
    addresses.map(async (item) => {
      try {
        if (addressesCoords[item.houseId]) return;

        const coords = await getCoordsByAddress(`${item.city}, ${item.street}, ${item.houseNumber}`);

        if (coords) {
          queryCache.setQueryData('addressesCoords', (prev: typeof addressesCoords) => ({
            ...prev,
            [item.houseId]: coords,
          }));
        }
      } catch (error) {
      }
    })
  );
}

export async function saveCourtArea({ courtId, polygon }) {
  const { minLat, maxLat, minLon, maxLon } = polygon.reduce((acc, coords) => ({
    minLat: coords[0] < acc.minLat ? coords[0] : acc.minLat,
    maxLat: coords[0] > acc.maxLat ? coords[0] : acc.maxLat,
    minLon: coords[1] < acc.minLon ? coords[1] : acc.minLon,
    maxLon: coords[1] > acc.maxLon ? coords[1] : acc.maxLon,
  }), { minLat: 99, maxLat: 0, minLon: 99, maxLon: 0 });

  const points = [];

  for (let lat = minLat + 0.0004; lat < maxLat; lat += 0.001) {
    for (let lon = minLon + 0.0004; lon < maxLon; lon += 0.001) {
      if (inside([lat, lon], polygon)) {
        points.push([lat, lon]);
      }
    }
  }

  const prev = queryCache.getQueryData<Models.Address[]>(['addresses', courtId]);

  const compare = (address: Models.Address, suggestion) => (
    address.city.includes(suggestion.city || suggestion.settlement)
      && address.street.includes(suggestion.street)
      && address.houseNumber === suggestion.house
  );

  const suggestions = (await axios.all(
    points.map(async (coords) => {
      const { data } = await axios.post('geocoder/getCacheData', {
        url: 'https://suggestions.dadata.ru/suggestions/api/4_1/rs/geolocate/address',
        lat: coords[0],
        lon: coords[1],
        radius_meters: 100,
        count: 50,
      }).catch(() => ({ data: [] }));

      if (!data?.suggestions) return [];

      return data.suggestions.reduce((acc, item) => {
        if (!!item.data.house && inside([item.data?.geo_lat, item.data?.geo_lon], polygon)) {
          return [...acc, item.data];
        }
        return acc;
      }, []);
    })
  )).reduce((acc, items) => {
    const filteredItems = items.filter(suggestion => (
      !acc.some(({ fias_id }) => fias_id === suggestion.fias_id)
    ));
    return [...acc, ...filteredItems];
  }, []);

  const added = suggestions.filter(suggestion => {
    return !prev.some(address => compare(address, suggestion));
  });

  await axios.all(
    added.map(async (item) => {
      const res = await saveAddress({
        judgeDeptId: courtId,
        houseNumber: `${item.house}${item.block ? `/${item.block}` : ''}`,
        ...item 
      });
      return res;
    })
  );

  const removed = prev.filter(address => {
    return !suggestions.some(suggestion => compare(address, suggestion));
  });

  await axios.all(
    removed.map(async ({ houseId }) => {
      const res = await removeAddress(houseId);
      return res;
    })
  );
}
