import React, { useMemo, useState, useCallback } from 'react';
import { Table, Button, Input, Popconfirm } from 'antd';
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import { useRecordModal } from '../..';
import { useQueryCache, useMutation } from 'react-query';
import { ColumnsType } from 'antd/lib/table';
import srv from '../../../services';

interface Props {
  onRefetch: () => void;
  isFetching: boolean;
  addresses: Models.Address[];
}

export default function JurisdictionTable({ onRefetch, isFetching, addresses }: Props) {
  const { onRemove } = useRemove(onRefetch);
  const { isAdmin } = useUser();
  const { filteredAddresses, search, onSearch } = useAddresses(addresses);
  const { onOpenAddModal, onOpenEditModal } = useRecordModal();

  const columns: ColumnsType<Models.Address> = [{
    dataIndex: 'address',
    title: () => (
      <Input
        allowClear
        size="small"
        placeholder="Поиск"
        value={search}
        onChange={e => onSearch(e.target.value)}
      />
    ),
    render: (_, row) => `${row.city}, ${row.street}, ${row.houseNumber}`
  }];

  if (isAdmin) {
    columns.push({
      dataIndex: 'actions',
      width: 35,
      align: 'right',
      title: () => (
        <Button
          title="Добавить адрес"
          size="small"
          icon={<PlusOutlined />}
          onClick={() => onOpenAddModal()}
        />
      ),
      render: (_, row) => (
        <Popconfirm
            title="Удалить адрес?"
            okText="Да"
            icon={<DeleteOutlined style={{ color: 'red' }} />}
            onConfirm={() => onRemove(row.houseId)}
          >
            <Button icon={<DeleteOutlined style={{ color: 'red' }} />}/>
          </Popconfirm>
      ),
    });
  }

  return (
    <Table
      loading={isFetching}
      columns={columns}
      dataSource={filteredAddresses}
      rowKey={row => row.houseId}
      showHeader={isAdmin}
      size="small"
      pagination={{
        pageSize: 100,
        showSizeChanger: false,
        hideOnSinglePage: true,
      }}
      onRow={row => ({
        onDoubleClick: () => onOpenEditModal(row.houseId),
      })}
    />
  );
}

function useAddresses(addresses: Props["addresses"]) {
  const [search, onSearch] = useState('');

  const filteredAddresses = useMemo(() => {
    return addresses.filter(a => {
      const fullAddress = `${a.city}, ${a.street}, ${a.houseNumber}`.toLowerCase();
      if (fullAddress.includes(search.toLocaleLowerCase())) {
        return true;
      }
      return false;
    });
  }, [search, addresses]);

  return { filteredAddresses, search, onSearch };
}

function useRemove(onRefetch){
  const [remove] = useMutation(srv.removeAddress,{
    onSuccess: () => onRefetch()
  });

  const onRemove = useCallback(async (recordId) => {
    await remove(recordId);
  }, [remove]);
  return { onRemove };
}

function useUser() {
  const user = useQueryCache().getQueryData<Models.User>('user');

  const isAdmin = useMemo(() => {
    return ['Admins', 'Laws'].some(role => user.roles.some(r => role === r.roleName));
  }, [user]);

  return { user, isAdmin };
}
