import React, { useCallback, useEffect } from 'react';
import { useMutation, useQuery } from 'react-query';
import { Form, Input, Button } from 'antd';
import { FormInstance, RuleObject } from 'antd/lib/form';
import srv from '../../../services';
import { BaseForm } from '../Auth.styled';
import { Alert } from './Registration.styled';
import Applicant from './Applicant';

export default function Registration() {
  const {
    form, isLoading, isSuccess, error, message, applicant, validatePasswordConfirm, ...formProps
  } = useRegistration();

  if (isSuccess) return (
    <Alert
      type="success"
      message={<>
        {message}
        <i>Если Вы не получили письмо-подтверждение регистрации, просьба проверить папку «Спам»</i>
      </>}
    />
  );

  return (
    <BaseForm
      layout="vertical"
      requiredMark={false}
      form={form}
      {...formProps}
    >
      <Form.Item name="applicant">
        <Applicant />
      </Form.Item>

      <Form.Item name="email" label="E-mail" rules={[{ required: true, type: 'email' }]}>
        <Input disabled={!applicant} />
      </Form.Item>

      <Form.Item name="username" label="Логин" rules={[{ required: true }]}>
        <Input disabled={!applicant} />
      </Form.Item>

      <Form.Item name="password" label="Пароль" rules={[{ required: true }]}>
        <Input.Password disabled={!applicant} />
      </Form.Item>

      <Form.Item
        name="password_confirm"
        label="Подтверждение пароля"
        dependencies={['password']}
        rules={[{ required: true }, validatePasswordConfirm]}
      >
        <Input.Password disabled={!applicant} />
      </Form.Item>

      {!!error && <Alert type="error" showIcon message={error} />}

      <Button
        htmlType="submit"
        type="primary"
        disabled={!applicant}
        loading={isLoading}
      >
        Зарегистрироваться
      </Button>
    </BaseForm>
  );
}

function useRegistration() {
  const [form] = Form.useForm();

  const { data: applicant } = useQuery<Models.Applicant>('foundApplicant');

  useEffect(() => {
    if (applicant?.email) {
      form.setFieldsValue({ email: applicant.email });
    }
  }, [form, applicant]);

  const validatePasswordConfirm = useCallback(({ getFieldValue }: FormInstance): RuleObject => ({
    validator(_, value) {
      if (!value || value === getFieldValue('password')) {
        return Promise.resolve();
      }
      return Promise.reject('Пароль и подтверждение не совпадают');
    },
  }), []);

  const [register, { isLoading, isSuccess, error, data: message, reset }] = useMutation(srv.regUser);

  const onFinish = useCallback((values) => {
    register({ ...values, inn: applicant.inn });
  }, [register, applicant]);

  const onValuesChange = useCallback(() => {
    if (error) reset();
  }, [error, reset]);

  useEffect(() => () => {
    reset();
  }, [reset]);

  return {
    form,
    isLoading,
    isSuccess,
    message,
    error,
    applicant,
    validatePasswordConfirm,
    onValuesChange,
    onFinish,
  };
}
