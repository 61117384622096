import React from 'react';
import { Alert } from 'antd';
import { Wrapper, Spin } from './ContentWrapper.styled';

interface Props {
  children: React.ReactNode;
  isLoading?: boolean;
  error?: unknown | string;
}

export default function ContentWrapper({ children, isLoading = false, error }: Props) {
  return (
    <Wrapper>
      {(() => {
        if (isLoading) return (
          <Spin size="large" />
        );

        if (error) return (
          <Alert
            type="error"
            showIcon
            message="Произошла ошибка при загрузке данных"
            description={error}
          />
        );

        return children;
      })()}
    </Wrapper>
  );
}
