import { createGlobalStyle } from 'styled-components';

export const Styles = createGlobalStyle`
  .ant-modal {
    &-mask {
      z-index: 1021;
    }

    &-wrap {
      z-index: 1022;
    }

    &-body {
      padding: 1rem;
      min-height: 2.5rem;
    }

    &-close {
      background: rgba(255, 255, 255, 0.7);
      border-radius: 0.3rem;

      &-x {
        width: 2rem;
        height: 2rem;
        line-height: 2.1rem;
      }

      &:focus {
        outline: 0;
      }
    }
  }
`;
