import React from 'react';
import { Form, Input } from 'antd';
import { Address } from './AddressFields.styled';

export default function AddressFields() {
  return (
    <>
      <Form.Item name={['address', 'city']} label="Населенный пункт" rules={[{ required: true }]}>
        <Input />
      </Form.Item>

      <Address>
        <Form.Item name={['address', 'street']} label="Улица" rules={[{ required: true }]}>
          <Input />
        </Form.Item>

        <Form.Item name={['address', 'house']} label="Дом" rules={[{ required: true }]}>
          <Input />
        </Form.Item>

        <Form.Item name={['address', 'flat']} label="Квартира">
          <Input />
        </Form.Item>

        <Form.Item name={['address', 'room']} label="Комната">
          <Input />
        </Form.Item>
      </Address>
    </>
  );
}
