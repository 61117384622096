import styled from 'styled-components';
import * as antd from 'antd';

export const InputNumber = styled(antd.InputNumber)`
  margin-right: 0.2rem;

  input {
    text-align: right;
  }

  &.ant-input-number-disabled {
    background-color: #f1f7f5;
    color: #000;
    
    &, * {
      cursor: default!important;
    }
  }
`;

