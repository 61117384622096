import React, { useState, useCallback } from 'react';
import { usePaginatedQuery, useQueryCache } from 'react-query';
import { PlusOutlined, EditOutlined } from '@ant-design/icons';
import { Table, Button } from 'antd';
import srv from '../../services';
import UserForm from './UserForm';
import { ContentWrapper, RecordModal, useRecordModal } from '..';

export default function Users() {
  const { recordId, onOpenAddModal, onOpenEditModal, onCloseModal } = useRecordModal();
  const {
    users, count, page, pageSize, isLoading, isFetching, error, onChangePage, onRefetchUsers,
  } = useUsers();

  return (
    <>
      <ContentWrapper key={page} isLoading={isLoading} error={error}>
        <Table
          columns={[{
            title: 'ID',
            dataIndex: 'id',
          }, {
            title: 'Логин',
            dataIndex: 'username',
          }, {
            title: 'E-mail',
            dataIndex: 'email',
          }, {
            title: 'Роли',
            dataIndex: 'roles',
            render: (roles: Models.User['roles']) => (
              roles.map(role => <div key={role.roleId}>{role.description}</div>)
            ),
          }, {
            title: 'Статус',
            dataIndex: 'userState',
          }, {
            dataIndex: 'actions',
            width: 35,
            align: 'right',
            title: () => (
              <Button
                title="Добавить адрес"
                size="small"
                icon={<PlusOutlined />}
                onClick={() => onOpenAddModal()}
              />
            ),
            render: (_, row) => (
              <Button
                title="Редактировать"
                size="small"
                icon={<EditOutlined />}
                onClick={() => onOpenEditModal(row.id)}
              />
            ),
          }]}
          dataSource={users}
          loading={isFetching}
          rowKey={row => row.id}
          size="small"
          pagination={{
            current: page,
            total: count,
            pageSize,
            showSizeChanger: false,
            hideOnSinglePage: true,
            size: 'default',
            position: ['bottomCenter'],
          }}
          onChange={pagen => onChangePage(pagen.current)}
          onRow={row => ({
            onDoubleClick: () => onOpenEditModal(row.id),
          })}
        />
      </ContentWrapper>

      <RecordModal>
        <UserForm
          recordId={recordId}
          onFinish={() => {
            onRefetchUsers();
            onCloseModal();
          }}
        />
      </RecordModal>
    </>
  );
}

function useUsers() {
  const queryCache = useQueryCache();

  const pageSize = 20;
  const [page, onChangePage] = useState(1);

  const {
    isLoading, isFetching, resolvedData, error
  } = usePaginatedQuery(['users', page, pageSize], srv.getUsers);

  const onRefetchUsers = useCallback(() => {
    queryCache.invalidateQueries(['users', page, pageSize]);
  }, [queryCache, page, pageSize]);

  return {
    users: resolvedData?.items ?? [],
    count: resolvedData?.totalCount ?? 0,
    page,
    pageSize,
    isLoading,
    isFetching,
    error,
    onChangePage,
    onRefetchUsers,
  };
}
