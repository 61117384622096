import styled from 'styled-components';
import * as antd from 'antd';

export const AddButton = styled(antd.Button)`
  margin-bottom: 1rem;
  margin-right: 1rem;
`;

export const TabPane = styled(antd.Tabs.TabPane)`
  padding-top: 0.5rem;
`;
