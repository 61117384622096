import React, { useEffect, useCallback } from 'react';
import { useMutation, useQueryCache } from 'react-query';
import { Form, Input, Button } from 'antd';
import { BaseForm, Alert, Actions } from './NewServiceForm.styled';
import srv from '../../../../../services';

interface Props {
  onSuccess: (payload) => void;
  onCancel: () => void;
}

export default function NewServiceForm({ onSuccess, onCancel }: Props) {
  const { isLoading, error, onSubmit, ...formProps } = useService(onSuccess);

  return (
    <BaseForm
      size="small"
      component="div"
      {...formProps}
    >
      <Form.Item name="name" rules={[{ required: true }]}>
        <Input
          placeholder="Наименование" 
        />
      </Form.Item>

      {!!error && <Alert type="error" message={error} />}

      <Actions>
        <Button
          type="primary"
          loading={isLoading}
          onClick={onSubmit}
        >
          Сохранить
        </Button>

        <Button
          onClick={onCancel}
        >
          Отмена
        </Button>
      </Actions>
    </BaseForm>
  );
}

function useService(onSuccess: Props['onSuccess']) {
  const queryCache = useQueryCache();

  const [form] = Form.useForm();

  const [submit, { isLoading, error, reset }] = useMutation(srv.saveServiceType, {
    onSuccess: ({ id }) => {
      queryCache.invalidateQueries('serviceTypes');
      onSuccess(id);
    },
  });

  const onSubmit = useCallback(() => {
    form.submit();
  }, [form]);

  const onKeyPress = useCallback((e: React.KeyboardEvent<HTMLFormElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      form.submit();
    }
  }, [form]);

  const onFinish = useCallback((values) => {
    if (error) reset();
    submit(values);
  }, [error, submit, reset]);

  useEffect(() => () => {
    reset();
  }, [reset]);
  
  return { form, isLoading, error, onSubmit, onKeyPress, onFinish };
}
