import React from 'react';
import { FormProps } from 'antd/lib/form';
import { Alert } from 'antd';
import { Form, Spin } from './BaseForm.styled';

interface Props {
  defaultValues?: Record<string, any>;
  isLoading?: boolean;
  error?: string | unknown;
  children: React.ReactNode;
}

export default function BaseForm({
  children, isLoading = false, error, defaultValues, initialValues, ...formProps
}: FormProps & Props) {
  if (isLoading) return <Spin size="large" />;

  if (error) return <Alert type="error" showIcon message={error} />;

  return (
    <Form
      autoComplete="off"
      validateMessages={{
        required: 'Обязательное поле',
        types: {
          email: 'Введен некорректный e-mail',
        },
      }}
      initialValues={{
        ...(defaultValues ?? {}),
        ...(initialValues ?? {}),
      }}
      {...formProps}
    >
      {children}
    </Form>
  );
}
