import React, { useMemo, useCallback } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import qs from 'query-string';
import { Modal } from './RecordModal.styled';

interface Props {
  children: React.ReactNode;
}

export default function RecordModal({ children }: Props) {
  const { modalVisible, onCloseModal } = useRecordModal();

  return (
    <Modal
      destroyOnClose
      footer={null}
      maskClosable={false}
      visible={modalVisible}
      onCancel={onCloseModal}
    >
      {children}
    </Modal>
  );
}

export function useRecordModal(data?: any[]) {
  const { pathname, search } = useLocation();
  const { push } = useHistory();

  const query = useMemo(() => {
    return qs.parse(search);
  }, [search]);

  const modalVisible = useMemo(() => {
    if (!query) return false;
    return Object.keys(query).some(key => ['add', 'edit'].includes(key));
  }, [query]);

  const recordId = useMemo(() => {
    const id = query.edit || '';
    return String(id);
  }, [query]);

  const formValues = useMemo(() => {
    if (data && query.edit) {
      return data.find(x => String(x.id) === query.edit);
    }
    return null;
  }, [query, data]);

  const onOpenAddModal = useCallback(() => {
    push(`${pathname}?${qs.stringify({ ...query, add: '', edit: undefined })}`);
  }, [push, pathname, query]);

  const onOpenEditModal = useCallback((id: string | number) => {
    push(`${pathname}?${qs.stringify({ ...query, edit: id, add: undefined })}`);
  }, [push, pathname, query]);

  const onCloseModal = useCallback(() => {
    push(`${pathname}?${qs.stringify({ ...query, edit: undefined, add: undefined })}`);
  }, [push, pathname, query]);

  return { recordId, modalVisible, formValues, onOpenAddModal, onOpenEditModal, onCloseModal };
}
