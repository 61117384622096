import styled from 'styled-components';
import * as antd from 'antd';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;

  > button {
    margin-left: 0.7rem;
  }
`;

export const Spin = styled(antd.Spin)`
  margin: 0.25rem 0 -0.25rem 0.1rem;
`;

export const Warning = styled.div`
  margin: 0.162rem 0 -0.162rem;
  font-size: 0.88em;
  color: #ff4d4f;
`;
