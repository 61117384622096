import React, { useState } from 'react';
import { usePaginatedQuery } from 'react-query';
import moment from 'moment';
import { Table } from 'antd';
import srv from '../../services';
import { ContentWrapper } from '..';

export default function Logs() {
  const { logs, count, page, pageSize, isLoading, isFetching, error, onChangePage } = useLogs();

  return (
    <ContentWrapper key={page} isLoading={isLoading} error={error}>
      <Table
        columns={[{
          title: 'ID',
          dataIndex: 'eventLogId',
        }, {
          title: 'Дата',
          dataIndex: 'createDate',
          render: val => val ? moment.utc(val).local().format('DD.MM.YYYY HH:mm') : null,
        }, {
          title: 'Пользователь',
          dataIndex: 'userName',
        }, {
          title: 'Событие',
          dataIndex: 'eventName',
        }, {
          title: 'Результат',
          dataIndex: 'success',
          render: val => val ? 'успешно' : 'неуспешно',
        }]}
        dataSource={logs}
        loading={isFetching}
        rowKey={row => row.eventLogId}
        size="small"
        onChange={pagen => onChangePage(pagen.current)}
        pagination={{
          current: page,
          total: count,
          pageSize,
          showSizeChanger: false,
          hideOnSinglePage: true,
          size: 'default',
          position: ['bottomCenter'],
        }}
      />
    </ContentWrapper>
  );
}

function useLogs() {
  const pageSize = 20;
  const [page, onChangePage] = useState(1);

  const {
    isLoading, isFetching, resolvedData, error
  } = usePaginatedQuery(['logs', page, pageSize], srv.getLogs);

  return {
    logs: resolvedData?.items ?? [],
    count: resolvedData?.totalCount ?? 0,
    page,
    pageSize,
    isLoading,
    isFetching,
    error,
    onChangePage,
  };
}
