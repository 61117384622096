import React, { useCallback, useEffect, useMemo } from 'react';
import { useMutation, useQuery, useQueryCache } from 'react-query';
import { Button, Form, Input, Alert, Select } from 'antd';
import srv from '../../../services';
import { BaseForm } from '../..';

interface Props {
  recordId?: string;
  onFinish: () => void;
}

export default function UserForm({ recordId, onFinish }: Props) {
  const { isSubmiting, submitingError, ...formProps } = useForm({ recordId, onFinish });
  const { initialValues, roles, isLoading, loadingError } = useData(recordId);

  return (
    <BaseForm
      size="small"
      scrollToFirstError
      isLoading={isLoading}
      error={loadingError}
      initialValues={initialValues}
      {...formProps}
    >
      <header>
        <h2>{`${recordId ? 'Редактирование' : 'Создание'} пользователя`}</h2>
      </header>

      <Form.Item name="username" label="Логин" rules={[{ required: true }]}>
        <Input />
      </Form.Item>

      <Form.Item name="email" label="E-mail" rules={[{ required: true }]}>
        <Input />
      </Form.Item>

      <Form.Item name="inn" label="ИНН организации">
        <Input />
      </Form.Item>

      <Form.Item name="roles" label="Роли" rules={[{ required: true }]}>
        <Select
          showSearch
          mode="multiple"
          optionFilterProp="children"
          getPopupContainer={e => e.parentElement}
        >
          {roles.map(item => (
            <Select.Option key={item.id} value={item.name}>{item.description}</Select.Option>
          ))}
        </Select>
      </Form.Item>

      {!!submitingError && <Alert type="error" showIcon message={submitingError} />}

      <footer>
        <Button htmlType="submit" type="primary" size="middle" loading={isSubmiting}>
          Сохранить
        </Button>

        <Button size="middle" onClick={onFinish}>
          Отмена
        </Button>
      </footer>
    </BaseForm>
  );
}

function useForm({ recordId, onFinish }: Props) {
  const [form] = Form.useForm();

  const [save, { isLoading, isSuccess, error, reset }] = useMutation(srv.saveUser);

  const onSubmitForm = useCallback((values) => {
    save({ ...values, id: recordId || undefined });
  }, [save, recordId]);

  useEffect(() => {
    if (isSuccess) {
      onFinish();
      reset();
    }
  }, [isSuccess, onFinish, reset]);

  return {
    form, isSubmiting: isLoading, submitingError: error, onFinish: onSubmitForm
  };
}

function useData(recordId: Props['recordId']) {
  const queryCache = useQueryCache();

  const userQ = useQuery(['user', recordId], srv.getUser);

  const initialValues = useMemo(() => {
    if (!userQ.data) return null;
    return {
      ...userQ.data,
      roles: userQ.data.roles.map(r => r.roleName),
    }
  }, [userQ.data]);

  const rolesQ = useQuery('roles', srv.getRoles, { initialData: [] });

  useEffect(() => () => {
    queryCache.removeQueries(['user', recordId]);
  }, [queryCache, recordId]);

  return {
    initialValues,
    roles: rolesQ.data,
    isLoading: userQ.isFetching || rolesQ.isFetching,
    loadingError: userQ.error || rolesQ.error,
  };
}
