import axios from 'axios';

export async function getGeolocation() {
  const pos: Position = await new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(resolve, reject);
  });

  const res = await axios.post('geocoder/getdata', {
    url: 'http://suggestions.dadata.ru/suggestions/api/4_1/rs/geolocate/address',
    lat: pos?.coords?.latitude,
    lon: pos?.coords?.longitude,
    radius_meters: 500,
  }, {
    timeout: 3000,
  });

  return res.data?.suggestions?.[0]?.data || {};
}

export async function getSuggestions(data) {
  if (!data?.query) return [];
  let obj: any;
  obj = {
    url: 'http://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address',
    locations: [{
      // region: queryCache.getQueryData<any>('geoLocation')?.region || 'Тюменская',
      region: 'Тюменская',
    }],
    restrict_value: true,
    query: data.query
  };
  if (data?.street)
  {
    obj = {from_bound: { "value": "street" }, to_bound: { "value": "street" }, ...obj}
  }
  const res = await axios.post('geocoder/getdata', obj , { timeout: 3000 });

  return res.data?.suggestions || [];
}