import styled from 'styled-components';

export const Wrapper = styled.article`
  display: flex;
  align-items: center;
  padding-bottom: 0.25rem;
  margin-bottom: 0.4rem;
  border-bottom: 1px solid #eee;

  &:last-of-type {
    border-bottom: none;
  }

  > * {
    flex: 1;
  }
`;

export const Fields = styled.div`
  display: flex;
  align-items: center;

  > * {
    margin-bottom: 0!important;

    &:first-child {
      flex: 1;
      margin-right: 0.3rem;
    }
  }
`;

export const Aside = styled.div`
  flex: 0;
  padding-left: 0.1rem;
  margin-right: -0.4rem;
`;
