import styled from 'styled-components';

export const Address = styled.div`
  display: flex;

  > * {
    flex: 1;
    margin-right: 0.33rem;

    &:first-child {
      flex: 2.5;
      max-width: 25rem;
    }

    &:last-child {
      margin-right: 0;
    }
  }
`;

