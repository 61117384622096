import styled from 'styled-components';

export const Wrapper = styled.article`
  display: flex;
  align-items: center;
  margin-bottom: 0.6rem;
  border-bottom: 1px solid #eee;

  &:last-of-type {
    border-bottom: none;
  }

  > * {
    flex: 1;
  }
`;

export const Aside = styled.div`
  flex: 0;
  padding-left: 0.1rem;
  margin-right: -0.4rem;
`;

export const Birth = styled.div`
  display: flex;
  margin-bottom: -0.1rem;

  > * {
    margin-right: 0.77rem;

    &:last-child {
      flex: 1;
      margin-right: 0;
    }
  }
`;

export const Document = styled.div`
  display: flex;
  margin-bottom: -0.1rem;

  > * {
    flex: 0.9;
    margin-right: 0.33rem;

    &:first-child {
      flex: 2;
      max-width: 20rem;
    }

    &:last-child {
      flex: 1.1;
      margin-right: 0;
    }

    label[for$='serial'] {
      padding-left: 0.4rem;
    }
  }
`;
