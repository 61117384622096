import styled from 'styled-components';
import * as antd from 'antd';

export const Wrapper = styled.div`
  position: relative;
  flex: 1;
  overflow: auto;
  padding: 0.9rem 1rem;
  background: #fff;
  box-shadow: 0 0 0.5rem rgba(0,0,0,0.05);
`;

export const Spin = styled(antd.Spin)`
  display: block;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-top: -18px;
  margin-left: -16px;
`;
