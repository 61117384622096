import React, { useCallback, useMemo } from 'react';
import { Switch, Route, Redirect, useLocation, useHistory } from 'react-router-dom';
import { useQueryCache } from 'react-query';
import { Menu, Alert, Button } from 'antd';
import { ExportOutlined, RedoOutlined } from '@ant-design/icons';
import { Spin, Errors, Wrapper, Sider, User, Title, Exit, Main, Caution } from './Layout.styled';

interface Props {
  isLoading: boolean;
  error?: unknown;
  navigation: {
    url: string;
    name?: string;
    access?: Models.Role['name'][];
    icon?: React.ReactNode;
    component: React.FunctionComponent;
  }[];
}

export default function MainLayout({ isLoading, error, navigation }: Props) {
  const { userNav, activeNavItem, onNavChange } = useNav(navigation);
  const { user, applicant, onLogout } = useUser();

  if (isLoading) return <Spin size="large" />;

  if (error) return (
    <Errors>
      <Alert
        type="error"
        showIcon
        message="Произошла ошибка при загрузке данных"
        description={error}
      />

      <Button icon={<RedoOutlined />} onClick={() => window.location.reload()}>
        Повторить попытку
      </Button>
    </Errors>
  );

  return (
    <>
      <Caution>Система находится на этапе опытной эксплуатации</Caution>

      <Wrapper>
        <Sider>
          <User>
            {applicant ? applicant.name : user.username}
          </User>

          <Menu
            theme="dark"
            mode="inline"
            selectedKeys={[activeNavItem?.url]}
          >
            {userNav.map(item => {
              if (!item.name) return null;
              return (
                <Menu.Item
                  key={item.url}
                  icon={item.icon}
                  onClick={() => onNavChange(item.url)}
                >
                  {item.name}
                </Menu.Item>
              );
            })}

            <Exit icon={<ExportOutlined />} onClick={onLogout}>
              Выход
            </Exit>
          </Menu>
        </Sider>

        <Main>
          <Title>{activeNavItem?.name}</Title>

          <Switch>
            {userNav.map(item => (
              <Route key={item.url} path={item.url} component={item.component} />
            ))}

            <Route render={() => <Redirect to={userNav[0].url} />} />
          </Switch>
        </Main>
      </Wrapper>
    </>
  );
}

function useNav(navigation: Props['navigation']) {
  const { pathname } = useLocation();
  const { push } = useHistory();

  const user = useQueryCache().getQueryData<Models.User>('user');

  const userNav = useMemo(() => {
    return navigation.filter(item => {
      if (!item.access?.length) return true;
      return item.access.some(role => user.roles.some(r => r.roleName === role));
    });
  }, [user, navigation]);

  const activeNavItem = useMemo(() => {
    return navigation.find(item => pathname.includes(item.url));
  }, [navigation, pathname]);

  const onNavChange = useCallback((val) => {
    push(val);
  }, [push]);

  return { userNav, activeNavItem, onNavChange };
}

function useUser() {
  const queryCache = useQueryCache();

  const user = queryCache.getQueryData<Models.User>('user');
  const applicant = queryCache.getQueryData<Models.Applicant>('applicant');

  const onLogout = useCallback(() => {
    localStorage.removeItem('token');
    queryCache.setQueryData('user', null);
  }, [queryCache]);

  return { user, applicant, onLogout };
}
