import React, { useMemo } from 'react';
import moment from 'moment';
import { uid } from 'react-uid';
import { Table } from './ApplicationDebt.styled';

interface Props {
  services: {
    name: string;
    amount: number;
    [field: string]: any;
  }[];
}

export default function ApplicationDebt({ services }: Props) {
  const sum = useMemo(() => {
    const servicesSum = services.reduce((acc, { amount }) => {
      return acc + Number(amount);
    }, 0);
    return Math.round((servicesSum) * 100) / 100;
  }, [services]);

  return (
    <Table
      columns={[
        {
          dataIndex: 'name',
          align: 'right',
          render: (val, row: Partial<typeof services[0]>) => {
            if (!row.period_start) return val;
            return `${val}
              (${moment(row.period_start).format('DD.MM.YYYY')} -
              ${moment(row.period_end).format('DD.MM.YYYY')})
            `;
          }
        },
        {
          dataIndex: 'amount',
          align: 'right',
          render: val => `${val} ₽`
        },
      ]}
      dataSource={[
        ...services,
        { name: 'Итого', amount: sum },
      ]}
      rowKey={row => uid(row)}
      showHeader={false}
      pagination={false}
    />
  );
}
