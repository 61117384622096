import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { useQuery, ReactQueryCacheProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query-devtools';
import { ConfigProvider } from 'antd';
import localeRU from 'antd/lib/locale-provider/ru_RU';
import {
  FormOutlined, ProfileOutlined, AimOutlined, SettingOutlined, HddOutlined, UserOutlined
} from '@ant-design/icons';
import queryCache from '../queryCache';
import srv from '../services';
import {
  Layout, Auth, PageApplications, PageApplicantInfo, PageJurisdiction, PageLogs, PageSettings, PageUsers
} from '.';
import { Styles } from './App.styled';
import 'antd/dist/antd.css';

export default function App() {
  return (
    <ReactQueryCacheProvider queryCache={queryCache}>
      <ConfigProvider locale={localeRU}>
        <Router>
          <Main />
          <Styles />
        </Router>
      </ConfigProvider>

      <ReactQueryDevtools
        position="bottom-right"
        toggleButtonProps={{ style: { filter: 'opacity(0.1)' } }}
      />
    </ReactQueryCacheProvider>
  );
}

function Main() {
  const {
    isLoading, data: user
  } = useQuery('user', srv.getCurrentUser, { refetchOnWindowFocus: false, retry: 0 });

  if (!user) return <Auth isLoading={isLoading} />;

  return <Portal />;
}

function Portal() {
  const { isLoading, error } = useQuery('applicant', srv.getApplicant);

  // useQuery('geoLocation', srv.getGeolocation);
  useQuery('geocoderAvailable', { initialData: true });

  return (
    <Layout
      isLoading={isLoading}
      error={error}
      navigation={[{
        url: '/applications', name: 'Заявления в суд', access: ['Applicants'],
        icon: <FormOutlined />, component: PageApplications,
      }, {
        url: '/logs', name: 'Мониторинг транзакций', access: ['Admins'],
        icon: <HddOutlined />, component: PageLogs,
      }, {
        url: '/users', name: 'Пользователи', access: ['Admins'],
        icon: <UserOutlined />, component: PageUsers,
      }, {
        url: '/jurisdiction', name: 'Территориальная подсудность',
        icon: <AimOutlined />, component: PageJurisdiction,
      }, {
        url: '/applicant-info', name: 'Информация о заявителе', access: ['Applicants'],
        icon: <ProfileOutlined />, component: PageApplicantInfo,
      }, {
        url: '/settings', name: 'Личные настройки',
        icon: <SettingOutlined />, component: PageSettings,
      }]}
    />
  );
}
