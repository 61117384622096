import styled from 'styled-components';
import * as antd from 'antd';

export const Spin = styled(antd.Spin)`
  margin-top: 0.5rem;
`;

export const Alert = styled(antd.Alert)`
  max-width: 23rem;
  margin-top: 0.3rem;
`;
