import React, { useState, useCallback } from "react";
import { usePaginatedQuery, useQueryCache, useQuery } from "react-query";
import moment from "moment";
import { Table, Tabs } from "antd";
import { DownloadOutlined, FileOutlined } from "@ant-design/icons";
import srv from "../../services";
import { ContentWrapper, RecordModal, useRecordModal, UploadButton } from "..";
import ApplicationForm from "./ApplicationForm";
import ApplicationDebt from "./ApplicationDebt";
import ApplicationStatuses from "./ApplicationStatuses";
import { AddButton, TabPane } from "./Applications.styled";

export default function Applications() {
  const {
    applications,
    count,
    page,
    pageSize,
    isLoading,
    isFetching,
    error,
    onChangePage,
    onRefetch,
  } = useApplications();
  const { onOpenAddModal, onCloseModal } = useRecordModal();

  return (
    <>
      <ContentWrapper key={page} isLoading={isLoading} error={error}>
        <AddButton
          type="primary"
          icon={<FileOutlined />}
          onClick={onOpenAddModal}
        >
          Подать заявление
        </AddButton>

        <UploadButton>
          <DownloadOutlined />
          Загрузить реестр
        </UploadButton>

        <Table
          columns={[
            {
              title: "ID",
              dataIndex: "lawsuitId",
            },
            {
              title: "Создано",
              dataIndex: "createDate",
              render: (val) =>
                val ? moment.utc(val).local().format("DD.MM.YYYY HH:mm") : null,
            },
            {
              title: "Адрес",
              dataIndex: ["debt", "address"],
            },
            {
              title: "Статус",
              dataIndex: ["lawsuitStateHistories", 0],
              render: (val) => val?.lawsuitState,
            },
            {
              title: "Дата статуса",
              dataIndex: ["lawsuitStateHistories", 0, "createDate"],
              render: (val) =>
                val ? moment.utc(val).local().format("DD.MM.YYYY HH:mm") : null,
            },
          ]}
          dataSource={applications}
          loading={isFetching}
          rowKey={(row) => row.lawsuitId}
          size="small"
          onChange={(pagen) => onChangePage(pagen.current)}
          pagination={{
            current: page,
            total: count,
            pageSize,
            showSizeChanger: false,
            hideOnSinglePage: true,
            size: "default",
            position: ["bottomCenter"],
          }}
          expandedRowRender={(row) => (
            <Tabs tabPosition="left" defaultActiveKey="court">
              <TabPane key="court" tab="Судебный участок">
                {row.debt.court}
              </TabPane>

              <TabPane key="debt" tab="Задолженность">
                <ApplicationDebt services={row.debt.services} />
              </TabPane>

              {!!row.debt.extraExpenses?.length && (
                <TabPane key="extraExpenses" tab="Доп. расходы">
                  <ApplicationDebt services={row.debt.extraExpenses} />
                </TabPane>
              )}

              {[row.representative, row.remark].some((val) => val) && (
                <TabPane key="info" tab="Доп. информация">
                  {!!row.representative && (
                    <div>Представитель: {row.representative}</div>
                  )}
                  {!!row.remark && <div>Комментарий: {row.remark}</div>}
                </TabPane>
              )}

              <TabPane key="statuses" tab="История изменения статусов">
                <ApplicationStatuses statuses={row.lawsuitStateHistories} />
              </TabPane>
            </Tabs>
          )}
        />
      </ContentWrapper>

      <RecordModal>
        <ApplicationForm onCancel={onCloseModal} onSuccess={onRefetch} />
      </RecordModal>
    </>
  );
}

function useApplications() {
  const queryCache = useQueryCache();

  const pageSize = 20;
  const [page, onChangePage] = useState(1);

  const { isLoading, isFetching, resolvedData, error } = usePaginatedQuery(
    ["applications", page, pageSize],
    srv.getApplications
  );

  useQuery("serviceTypes", srv.getServiceTypes, { initialData: [] });
  useQuery("expenseTypes", srv.getExpenseTypes, { initialData: [] });
  useQuery("courts", srv.getCourts, { initialData: [] });

  const onRefetch = useCallback(() => {
    queryCache.invalidateQueries(["applications", page, pageSize]);
  }, [queryCache, page]);

  return {
    applications: resolvedData?.items ?? [],
    count: resolvedData?.totalCount ?? 0,
    page,
    pageSize,
    isLoading,
    isFetching,
    error,
    onChangePage,
    onRefetch,
  };
}
