import React, { useCallback, useMemo } from 'react';
import { useLocation, useHistory } from 'react-router';
import qs from 'query-string';
import { useQuery, useQueryCache } from 'react-query';
import srv from '../../services';
import { ContentWrapper, RecordModal, useRecordModal } from '..';
import JurisdictionAddAddressesForm from './JurisdictionForm/JurisdictionAddAddressesForm';
import JurisdictionTable from './JurisdictionTable';
import JurisdictionSearch from './JurisdictionSearch';
import { Wrapper, CourtSelect, Addresses } from './Jurisdiction.styled';

export default function Jurisdiction() {
  const { currentCourt, onChangeCourt } = useCourtsSelect();
  const { courts, addresses, isLoading, isFetching, onRefetch } = useJurisdiction(currentCourt);

  const { recordId, onCloseModal } = useRecordModal();
  const user = useQueryCache().getQueryData<Models.User>('user');

  return (
    <>
      <ContentWrapper isLoading={isLoading}>
        <Wrapper>
          {user.roles.some(r => r.roleName === 'Applicants') ? (
            <JurisdictionSearch />
          ) : (
            <>
              <CourtSelect
                placeholder="Выберите судебный участок"
                showSearch
                optionFilterProp="children"
                value={currentCourt}
                onChange={onChangeCourt}
              >
                {courts.map(court => (
                  <CourtSelect.Option key={court.judgeDeptId} value={court.judgeDeptId}>
                    {court.name}
                  </CourtSelect.Option>
                ))}
              </CourtSelect>

              {!!currentCourt && (
                <Addresses>
                  <JurisdictionTable
                    isFetching={isFetching}
                    addresses={addresses}
                    onRefetch={() => onRefetch()}
                  />
                </Addresses>
              )}
            </>
          )}
        </Wrapper>
      </ContentWrapper>

      <RecordModal>
        <JurisdictionAddAddressesForm
          recordId={recordId}
          courtId={currentCourt}
          onFinish={() => {
            onRefetch();
            onCloseModal();
          }}
        />
      </RecordModal>
    </>
  );
}

function useJurisdiction(currentCourt: number) {
  const queryCache = useQueryCache();

  const courtsQ = useQuery('courts', srv.getCourts, { initialData: [] });
  const addressesQ = useQuery(['addresses', currentCourt], srv.getCourtAddresses);

  const onRefetch = useCallback(() => {
    queryCache.invalidateQueries(['addresses', currentCourt]);
  }, [queryCache, currentCourt]);

  return {
    currentCourt,
    courts: courtsQ.data,
    addresses: addressesQ.data,
    isLoading: courtsQ.isLoading || addressesQ.isLoading,
    isFetching: addressesQ.isFetching,
    onRefetch,
  };
}

function useCourtsSelect() {
  const { pathname, search } = useLocation();
  const { push } = useHistory();

  const query = useMemo(() => {
    return qs.parse(search);
  }, [search]);

  const currentCourt = useMemo(() => {
    return query.court ? Number(query.court) : null;
  }, [query.court]);

  const onChangeCourt = useCallback((court) => {
    push(`${pathname}?${qs.stringify({ ...query, court })}`);
  }, [push, pathname, query]);

  return { currentCourt, onChangeCourt};
}
