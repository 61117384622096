import React, { useCallback } from 'react';
import { useMutation, useQueryCache } from 'react-query';
import { Form, Input } from 'antd';
import srv from '../../services';
import { ContentWrapper, BaseForm } from '..';

export default function ApplicantInfo() {
  const { initialValues, isLoading, isSuccess, error, ...formProps } = useForm();

  return (
    <ContentWrapper>
      <BaseForm
        layout="vertical"
        requiredMark={false}
        initialValues={initialValues}
        {...formProps}
      >
        <section>
          <Form.Item name="name" label="Наименование">
            <Input readOnly />
          </Form.Item>

          <Form.Item name="address" label="Юридический адрес">
            <Input readOnly />
          </Form.Item>

          <Form.Item name="mail_address" label="Адрес для корреспонденции">
            <Input readOnly />
          </Form.Item>

          <Form.Item name="phone" label="Номер телефона">
            <Input readOnly />
          </Form.Item>

          <Form.Item name="fax" label="Факс">
            <Input readOnly />
          </Form.Item>
        </section>

        <section>
          <h3>Реквизиты для перечисления задолженности</h3>

          <Form.Item name="inn" label="ИНН">
            <Input readOnly />
          </Form.Item>

          <Form.Item name="kpp" label="КПП">
            <Input readOnly />
          </Form.Item>

          <Form.Item name="bik" label="БИК">
            <Input readOnly />
          </Form.Item>

          <Form.Item name="correspondent_account" label="Кор. счет">
            <Input readOnly />
          </Form.Item>

          <Form.Item name="bank" label="Банк получателя">
            <Input readOnly />
          </Form.Item>

          <Form.Item name="checking_account" label="Расчетный счет">
            <Input readOnly />
          </Form.Item>
        </section>

        {/* {isSuccess && <Alert type="success" message="Информация успешно сохранена" />}

        {!!error && <Alert type="error" showIcon message={error} />}

        <footer>
          <Button
            htmlType="submit"
            type="primary"
            loading={isLoading}
          >
            Сохранить
          </Button>
        </footer> */}
      </BaseForm>
    </ContentWrapper>
  );
}

function useForm() {
  const queryCache = useQueryCache();
  const applicant = queryCache.getQueryData<Models.Applicant>('applicant');

  const [update, { isLoading, isSuccess, error, reset }] = useMutation(srv.updateApplicant, {
    onSuccess: data => queryCache.setQueryData('applicant', data),
  });

  const onFinish = useCallback((values) => {
    update(values);
  }, [update]);

  const onValuesChange = useCallback(() => {
    if (error || isSuccess) reset();
  }, [error, isSuccess, reset]);

  return {
    initialValues: applicant, isLoading, isSuccess, error, onValuesChange, onFinish
  };
}
