import React from 'react';
import { Form, Input, DatePicker, Select } from 'antd';
import { FormListFieldData } from 'antd/lib/form/FormList';
import { RemoveListItem } from '../../ApplicationForm.styled';
import { Wrapper, Aside, Birth, Document } from './Person.styled';

interface Props {
  removable?: boolean;
  onRemove: (id: number) => void;
}

export default function Person({ name, removable = true, onRemove }: FormListFieldData & Props) {
  return (
    <Wrapper>
      <div>
        <Form.Item name={[name, 'name']} label="ФИО" rules={[{ required: true }]}>
          <Input />
        </Form.Item>

        <Birth>
          <Form.Item name={[name, 'date_of_birth']} label="Дата рождения" rules={[{ required: true }]}>
            <DatePicker
              format="DD.MM.YYYY"
              allowClear={false}
              getPopupContainer={e => e.parentElement}
            />
          </Form.Item>

          <Form.Item name={[name, 'place_of_birth']} label="Место рождения">
            <Input />
          </Form.Item>
        </Birth>

        <Form.Item name={[name, 'address']} label="Адрес проживания" rules={[{ required: true }]}>
          <Input />
        </Form.Item>

        <Form.Item name={[name, 'job_place']} label="Место работы">
          <Input />
        </Form.Item>

        <Document>
          <Form.Item name={[name, 'doc_type']} label="Тип документа">
            <Select
              options={[
                { value: 'СНИЛС' },
                { value: 'ИНН' },
                { value: 'Паспорт' },
              ]}
              getPopupContainer={e => e.parentElement}
            />
          </Form.Item>

          <Form.Item name={[name, 'serial']} label="Серия">
            <Input />
          </Form.Item>

          <Form.Item name={[name, 'number']} label="Номер">
            <Input />
          </Form.Item>
        </Document>
      </div>

      {removable && (
        <Aside>
          <RemoveListItem
            title="Удалить"
            onClick={() => onRemove(name)}
          />
        </Aside>
      )}
    </Wrapper>
  );
}
