import styled from 'styled-components';
import * as antd from 'antd';

export const CourtSelect = styled(antd.Select)`
  width: 100%;
`;

export const Spin = styled(antd.Spin)`
  margin: 0.2rem 0 -0.2rem 0.1rem;
`;

export const Warning = styled.div`
  margin: 0.188rem 0 -0.188rem;
  font-size: 0.88em;
  color: #ff4d4f;
`;

export const Val = styled.div`
  margin: 0.1rem 0 -0.1rem;
`;
