import React, { useEffect } from 'react';
import { MoneyInput } from '../../..';

interface Props {
  services: [{ amount: number }];
  value?: number;
  onChange?: (val: number) => void
}

export default function Sum({ services, value, onChange }: Props) {
  useSum({ services, onChange });

  return (
    <MoneyInput disabled value={value} />
  );
}

function useSum({ services, onChange }: Omit<Props, 'value'>) {
  useEffect(() => {
    const sum = services.reduce((acc, _) => acc + (_?.amount || 0), 0)
    onChange(sum || null);
  }, [services, onChange]);
}
