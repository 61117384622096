import styled from 'styled-components';
import * as antd from 'antd';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
`;

export const CourtSelect = styled(antd.Select)`
  width: 100%;
`;

export const Addresses = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
  padding-top: 1rem;

  > * {
    width: 100%!important;
    order: 1;
    margin-bottom: 1rem;
  }

  @media (min-width: 992px) {
    flex-direction: row;
    overflow: auto;

    > * {
      width: 50%!important;
      order: 0;
      margin-bottom: 0;
    }
  }

  .ant-table-wrapper {
    order: 0;
    overflow: auto;

    @media (min-width: 992px) {
      margin-left: 0.7rem;
      margin-bottom: 0;

      &:first-child {
        margin-left: 0;
        width: 100%!important;
      }
    }

    th {
      position: sticky!important;
      z-index: 1;
      top: 0;
    }
  }
`;
