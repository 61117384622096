import React from 'react';
// import { useQuery } from 'react-query';
//import AddressAutocomplete from './AddressAutocomplete';
import AddressFields from './AddressFields';

export default function Address(props) {
  //const { data: geocoderAvailable } = useQuery('geocoderAvailable');

  // if (geocoderAvailable) return (
  //   <AddressAutocomplete {...props} />
  // );

  return (
    <AddressFields {...props} />
  );
}
