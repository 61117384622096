import React, { useEffect, useMemo } from 'react';
import { useQuery, useQueryCache, useMutation } from 'react-query';
// import { Button } from 'antd';
// import { FileDoneOutlined } from '@ant-design/icons';
import { MoneyInput } from '../../..';
import srv from '../../../../services';
import { Wrapper, Warning, Spin } from './Tollage.styled';

interface Props {
  amount: number;
  fine: number;
  value?: number;
  onChange?: (val: number) => void
}

export default function Tollage({ amount, fine, value, onChange }: Props) {
  const { isDepsFilled, isLoading } = useTollage({ amount, fine, onChange });

  if (isLoading) {
    return <Spin size="small" />;
  }

  if (!isDepsFilled) {
    return <Warning>Для расчета госпошлины заполните сумму задолженности</Warning>;
  }

  return (
    <Wrapper>
      <MoneyInput value={value} disabled />

      {/* <Button
        type="primary"
        icon={<FileDoneOutlined />}
      >
        Скачать квитанцию
      </Button> */}
    </Wrapper>
  );
}

function useTollage({ amount, fine, onChange }: Omit<Props, 'value'>) {
  const queryCache = useQueryCache();

  const isDepsFilled = useMemo(() => {
    return [amount].every(field => typeof field === 'number');
  }, [amount]);

  const { data: tollage, clear } = useQuery<number>('tollage');

  useEffect(() => {
    onChange(tollage || null);
  }, [tollage, onChange]);

  const [request, { isLoading, reset }] = useMutation(srv.getTollage, {
    onSuccess: data => queryCache.setQueryData('tollage', data),
    onError: () => queryCache.setQueryData('tollage', null),
  });

  useEffect(() => {
    if (isDepsFilled) {
      clear();
      request({ amount, fine, expenseAmount: 0 });
    }
  }, [isDepsFilled, amount, fine, clear, request]);

  useEffect(() => () => {
    clear();
    reset();
  }, [clear, reset]);

  return { isDepsFilled, isLoading };
}
