import axios from 'axios';

export async function getUsers(page: number, limit: number): Promise<Models.Paginated<Models.User>> {
  const res = await axios.get('user/list', {
    params: { limit, offset: limit * (page - 1) },
  });
  return res.data;
}

export async function getUser(id): Promise<Models.User> {
  if (!id) return null;
  const res = await axios.get(`user/${id}`);
  return res.data;
}

export async function saveUser({ id, ...data }) {
  const res = await axios.post(id ? `user/update/${id}` : 'user/createuser', data);
  return res.data;
}

export async function getRoles(): Promise<Models.Role[]> {
  const res = await axios.get('dict/role/list');
  return res.data.items;
}
