import styled from 'styled-components';

export const Message = styled.h3`
  text-align: center;
  padding-right: 0.5rem;
  margin-top: -0.1rem;

  .anticon svg {
    color: #00af26;
    margin-right: 0.3rem;
  }
`;

export const Actions = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1.3rem;

  > * {
    margin-right: 0.7rem;

    &:last-child {
      margin-right: 0;
    }
  }
`;

export const Warning = styled.div`
  text-align: center;
  color: #c10000;
  font-size: 1.1em;
  margin-top: 1.13rem;
`;
