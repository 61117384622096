import styled from 'styled-components';
import * as antd from 'antd';
import { BaseForm as Form } from '../../../..';

export const BaseForm = styled(Form)`
  width: 300px;
`;

export const Alert = styled(antd.Alert)`
  padding: 0.1rem 0.5rem!important;
  font-size: 0.92em;
`;

export const Actions = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 0.5rem;

  > * {
    margin-right: 0.5rem;

    &:last-child {
      margin-right: 0;
    }
  }
`;
