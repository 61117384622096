import React, { useState, useEffect, useMemo } from 'react';
import { useQuery } from 'react-query';
import { useDebounce } from 'use-debounce';
import { Form, Input, AutoComplete } from 'antd';
import srv from '../../../../services';

interface Props {
  value?: {
    city: string;
    street: string;
    house: string;
    flat: string;
  };
  onChange?: (val) => void
}

export default function AddressAutocomplete({ value, onChange }: Props) {
  const { query, suggestions, onQueryChange } = useAutocomplete({ value, onChange });

  return (
    <Form.Item label="Адрес" required>
      <AutoComplete
        defaultActiveFirstOption={false}
        options={suggestions}
        value={query}
        onChange={onQueryChange}
      >
        <Input />
      </AutoComplete>
    </Form.Item>
  );
}

function useAutocomplete({ onChange }: Props) {
  const [query, onQueryChange] = useState(null);

  const [debouncedQuery] = useDebounce({query}, 300);

  const { data: suggestions } = useQuery(['suggestions', debouncedQuery], srv.getSuggestions);

  const address = useMemo(() => {
    if (query === null) return null;

    return ['city', 'street', 'house', 'house_fias_id'].reduce((acc, field) => {
      if (!suggestions?.[0]?.data) return acc;
      if (Array.isArray(field)) {
        return {
          ...acc,
          [field[0]]: suggestions[0].data[field[0]] || suggestions[0].data[field[1]],
        };
      }
      return {
        ...acc,
        [field]: suggestions[0].data[field],
      };
    }, { });
  }, [query, suggestions]);

  useEffect(() => {
    if (address !== null) onChange(address);
  }, [address, onChange]);

  return { query, suggestions, onQueryChange };
}
