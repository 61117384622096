import styled from 'styled-components';

export const Loading = styled.div`
  font-style: italic;

  .ant-spin {
    margin-right: 0.3rem;
  }
`;

export const Info = styled.div`
  font-size: 0.9em;
  margin-top: -0.3rem;

  b {
    color: #555;

    &::after {
      content: ':';
    }

    &:empty::after {
      content: '';
    }
  }
`;
