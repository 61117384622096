// import React, { useEffect, useMemo } from 'react';
// import { useQuery, useQueryCache, useMutation } from 'react-query';
// import srv from '../../../../services';
// import { Warning, Spin, Val } from './Court.styled';

// interface Props {
//   address: {
//     city: string;
//     street: string;
//     house: string;
//   };
//   value?: number;
//   onChange?: (val: number) => void
// }

// export default function Court({ address, onChange }: Props) {
//   const { value, isAddressFilled, isLoading } = useCourt({ address, onChange });

//   if (isLoading) {
//     return <Spin size="small" />;
//   }

//   if (!isAddressFilled) {
//     return <Warning>Для определения участка заполните адрес</Warning>;
//   }

//   if (!value)  {
//     return <Val>Участок не определен</Val>;
//   }

//   return (
//     <Val>{value}</Val>
//   );
// }

// function useCourt({ address, onChange }: Omit<Props, 'value'>) {
//   const queryCache = useQueryCache();

//   const isAddressFilled = useMemo(() => {
//     if (!address) return false;
//     return ['city', 'street', 'house'].every(field => address[field]);
//   }, [address]);

//   const { data: court, clear } = useQuery<Models.Court>('foundCourt');

//   useEffect(() => {
//     if (isAddressFilled) onChange(court?.judgeDeptId || null);
//   }, [isAddressFilled, court, onChange]);

//   const [request, { isLoading, reset }] = useMutation(srv.searchCourt, {
//     onSuccess: data => queryCache.setQueryData('foundCourt', data),
//     onError: () => queryCache.setQueryData('foundCourts', null),
//   });

//   useEffect(() => {
//     if (isAddressFilled) {
//       clear();
//       request(address);
//     }
//   }, [isAddressFilled, address, clear, request]);

//   useEffect(() => () => {
//     clear();
//     reset();
//   }, [clear, reset]);

//   return { value: court?.name, isAddressFilled, isLoading };
// }

import React from 'react';
import { useQuery } from 'react-query';
import srv from '../../../../services';
import { CourtSelect } from './Court.styled';
import { Form } from 'antd';

interface Props {
  value?: number;
  onChange?: (val: number) => void
}

export default function Court({ onChange }: Props) {
  const { courts } = useCourt({ onChange });

  return (
    <Form.Item name="courtId" label="Судебный участок" rules={[{ required: true }]}>
    <CourtSelect
        placeholder="Выберите судебный участок"
        showSearch
        optionFilterProp="children"
        // value={currentCourt}
        // onChange={onChangeCourt}
        getPopupContainer={e => e.parentElement}
      >
        {courts.map(court => (
          <CourtSelect.Option key={court.judgeDeptId} value={court.judgeDeptId}>
            {court.name}
          </CourtSelect.Option>
        ))}
    </CourtSelect>  
    </Form.Item>
  );
}

function useCourt(onChange) {
  // const queryCache = useQueryCache();
  const courtsQ = useQuery('courts', srv.getCourts, { initialData: [] });

  return { courts: courtsQ.data };
}
