import React, { useEffect } from 'react';
import { useParams, useHistory } from 'react-router';
import { useQuery, useQueryCache } from 'react-query';
import srv from '../../../services';
import { Spin, Alert } from './SettingsEmailConfirm.styled';

export default function SettingsEmailConfirm() {
  const { isLoading, error } = useEmailConfirm();

  if (isLoading) return <Spin />;

  if (error) return (
    <Alert type="error" showIcon message={error} />
  );

  return null;
}

function useEmailConfirm() {
  const queryCache = useQueryCache();
  const { push } = useHistory();
  const { id } = useParams();
  const { isLoading, isSuccess, error } = useQuery(['changeEmailConfirm', id], srv.changeUserEmailConfirm);

  useEffect(() => {
    if (isSuccess) {
      queryCache.refetchQueries('user');
      push('/settings');
    }
  }, [isSuccess, queryCache, push]);

  return { isLoading, error };
}
