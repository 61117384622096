import styled from 'styled-components';

export const Status = styled.div`
  display: flex;
  align-items: baseline;
  margin-bottom: 0.5rem;

  b {
    font-size: 1.05em;
    font-weight: 500;
    margin-left: 0.5rem;
  }
`;
