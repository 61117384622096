import React, { useCallback, useEffect, useState } from 'react';
import { useMutation, useQueryCache } from 'react-query';
import { Form, Input, Button, Alert } from 'antd';
import { FormInstance, RuleObject } from 'antd/lib/form';
import srv from '../../../services';
import { BaseForm } from '../..';

export default function SettingsPassword() {
  const {
    isEditing, isLoading, isSuccess, error, validatePasswordConfirm, onToggleForm, ...formProps
  } = usePassword();

  if (!isEditing) return (
    <>
      {isSuccess && <>
        <Alert type="success" message="Пароль успешно изменен" /><br />
      </>}

      <Button type="primary" onClick={onToggleForm}>
        Изменить пароль
      </Button>
    </>
  );

  return (
    <BaseForm
      layout="vertical"
      requiredMark={false}
      {...formProps}
    >
      <Form.Item name="oldPassword" label="Старый пароль" rules={[{ required: true }]}>
        <Input.Password />
      </Form.Item>

      <Form.Item name="newPassword" label="Новый пароль" rules={[{ required: true }]}>
        <Input.Password />
      </Form.Item>

      <Form.Item
        name="confirmPassword"
        label="Подтверждение нового пароля"
        rules={[{ required: true }, validatePasswordConfirm]}
      >
        <Input.Password />
      </Form.Item>

      {!!error && <Alert type="error" showIcon message={error} />}

      <Button htmlType="submit" type="primary" loading={isLoading}>
        Сохранить пароль
      </Button>

      <Button onClick={onToggleForm}>
        Отмена
      </Button>
    </BaseForm>
  );
}

function usePassword() {
  const [isEditing, setEditing] = useState(false);

  const [form] = Form.useForm();

  const queryCache = useQueryCache();
  const user = queryCache.getQueryData<Models.User>('user');
  const [changePassword, { isLoading, isSuccess, error, reset }] = useMutation(srv.changeUserPassword);

  const validatePasswordConfirm = useCallback(({ getFieldValue }: FormInstance): RuleObject => ({
    validator(_, value) {
      if (!value || value === getFieldValue('newPassword')) {
        return Promise.resolve();
      }
      return Promise.reject('Пароль и подтверждение не совпадают');
    },
  }), []);

  useEffect(() => {
    if (isSuccess) {
      form.resetFields();
      setEditing(false);
    }
  }, [isSuccess, form]);

  const onToggleForm = useCallback(() => {
    setEditing(prev => !prev);
    form.resetFields();
    reset();
  }, [form, reset]);

  const onFinish = useCallback((values) => {
    changePassword({ id: user.id, ...values });
  }, [changePassword, user]);

  const onValuesChange = useCallback(() => {
    if (error) reset();
  }, [error, reset]);

  return {
    form, isEditing, isLoading, isSuccess, error,
    validatePasswordConfirm, onToggleForm, onValuesChange, onFinish
  };
}
