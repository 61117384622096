import React from 'react';
import moment from 'moment';
import { Status } from './ApplicationStatuses.styled';

interface Props {
  statuses: Models.Application['lawsuitStateHistories']
}

export default function ApplicationStatuses({ statuses }: Props) {
  if (!statuses) return null;

  return (
    <>
      {statuses.map((status, i) => (
        <Status key={i}>
          {moment.utc(status.createDate).local().format('DD.MM.YYYY HH:mm')}
          <b>{status.lawsuitState}</b>
        </Status>
      ))}
    </>
  );
}
