import React, { useCallback, useEffect } from 'react';
import { useMutation } from 'react-query';
import { Form, Button, Input, DatePicker, Alert } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import srv from '../../../services';
import { BaseForm, MoneyInput } from '../..';
import { PeriodSum } from './ApplicationForm.styled';
import Address from './Address';
import Court from './Court';
import Debtor from './Debtor';
import Expense from './Expense';
import Service from './Service';
import Sum from './Sum';
import Tollage from './Tollage';
import Print from './Print';

interface Props {
  onCancel: () => void;
  onSuccess: () => void;
}

export default function ApplicationForm({ onCancel, onSuccess }: Props) {
  const { isLoading, error, result, ...formProps } = useForm(onSuccess);

  if (result) return <Print values={result} />;

  return (
    <BaseForm
      size="small"
      scrollToFirstError
      defaultValues={{ respondents: [{ guardian: [{}] }], services: [{}] }}
      {...formProps}
    >
      <header>
        <h2>Заявление о выдаче судебного приказа</h2>
      </header>

      <section>
        <Form.List name="respondents">
          {(fields, { add, remove }) => (
            <>
              <h3>Должники</h3>

              {fields.map(respondent => (
                <Debtor {...respondent} onRemove={remove} removable={fields.length > 1} />
              ))}

              <Button icon={<PlusOutlined />} onClick={() => add()}>
                Добавить должника
              </Button>
            </>
          )}
        </Form.List>
      </section>

      <section>
        <h3>Адрес задолженности</h3>

        <Form.Item name="address">
          <Address />
        </Form.Item>

        
              <Court
                // address={val('address')}
              />
            
        {/* <Form.Item dependencies={['address', ['address', 'city'], ['address', 'street'], ['address', 'house']]} noStyle>
          {({ getFieldValue: val }) => (
            <Form.Item name="courtId" label="Судебный участок" rules={[{ required: true }]}>
              <Court
                address={val('address')}
              />
            </Form.Item>
          )}
        </Form.Item> */}
      </section>

      <section>
        <h3>Предоставляемые услуги</h3>

        <Form.Item name="accountNumber" label="Лицевой счет" rules={[{ required: true }]}>
          <Input />
        </Form.Item>

        <Form.List name="services">
          {(fields, { add, remove }) => (
            <section>
              {fields.map(field => (
                <Service {...field} onRemove={remove} removable={fields.length > 1} />
              ))}

              <Button icon={<PlusOutlined />} onClick={() => add()}>Добавить услугу</Button>
            </section>
          )}
        </Form.List>

        <PeriodSum>
          <Form.Item name="period" label="Период долга" rules={[{ required: true }]}>
            <DatePicker.RangePicker
              allowClear={false}
              format="DD.MM.YYYY"
              getPopupContainer={e => e.parentElement}
            />
          </Form.Item>

          <Form.Item
            shouldUpdate={(prev, next) => prev.services.some((_, i) => _?.amount !== next.services?.[i]?.amount)}
          >
            {({ getFieldValue: val }) => (
              <Form.Item name="amount" label="Общая сумма задолженности" required>
                <Sum services={val('services')} />
              </Form.Item>
            )}
          </Form.Item>
        </PeriodSum>

        <PeriodSum>
          <Form.Item name="period_peni" label="Период расчета пени">
            <DatePicker.RangePicker
              allowClear={false}
              format="DD.MM.YYYY"
              getPopupContainer={e => e.parentElement}
            />
          </Form.Item>

          <Form.Item name="fine" label="Сумма пени">
            <MoneyInput />
          </Form.Item>
        </PeriodSum>

        <Form.Item dependencies={['amount', 'fine']}>
          {({ getFieldValue: val }) => (
            <Form.Item name="fee_amount" label="Госпошлина" required>
              <Tollage amount={val('amount')} fine={val('fine')} />
            </Form.Item>
          )}
        </Form.Item>
      </section>

      <section>
        <h3>Доп. расходы</h3>

        <Form.List name="extraExpenses">
          {(fields, { add, remove }) => (
            <section>
              {fields.map(field => (
                <Expense {...field} onRemove={remove} />
              ))}

              <Button icon={<PlusOutlined />} onClick={() => add()}>Добавить расходы</Button>
            </section>
          )}
        </Form.List>
      </section>

      <section>
        <h3>Доп. информация</h3>

        <Form.Item name="representative" label="Представитель" rules={[{ required: true }]}>
          <Input />
        </Form.Item>

        <Form.Item name="remark" label="Комментарий">
          <Input.TextArea />
        </Form.Item>
      </section>

      {!!error && <Alert type="error" showIcon message={error} />}

      <footer>
        <Button htmlType="submit" type="primary" size="middle" loading={isLoading}>
          Сформировать заявление
        </Button>

        <Button size="middle" onClick={onCancel}>Отмена</Button>
      </footer>
    </BaseForm>
  );
}

function useForm(onSuccess: Props['onSuccess']) {
  const [request, { isLoading, isSuccess, error, data, reset }] = useMutation(srv.saveApplication);

  const onFinish = useCallback((values) => {
    if (error) reset();
    request(values);
  }, [error, request, reset]);

  useEffect(() => {
    if (isSuccess) onSuccess();
  }, [isSuccess, onSuccess]);

  return { isLoading, error, result: data, onFinish };
}
