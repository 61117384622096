import styled from 'styled-components';
import * as antd from 'antd';

export const Spin = styled(antd.Spin)`
  display: block;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -16px;
  margin-top: -16px;
`;

export const Errors = styled.div`
  padding: 0.5rem;

  @media (min-width: 640px) {
    padding: 2rem;
  }

  button {
    display: block;
    margin: 1rem auto;
  }
`;

export const Wrapper = styled(antd.Layout)`
  min-height: calc(100vh - 2rem);
`;

export const Caution = styled.div`
  height: 2rem;
  line-height: 2rem;
  color: #fff;
  background-color: #a2242f;
  text-align: center;
`;

export const Exit = styled(antd.Menu.Item)`
  position: absolute!important;
  bottom: 0;
  margin: 0!important;
  background-color: #000;
`;

export const Sider = styled(antd.Layout.Sider)`
  position: fixed;
  left: 0;
  height: calc(100vh - 2rem);
  width: 270px!important;
  max-width: 270px!important;
  overflow: auto;
  padding-bottom: 3rem;
`;

export const User = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0.17rem;
  padding: 0.77rem 0;
  color: #fdcca2;
`;

export const Title = styled.h1`
  padding: 0 0.5rem;
  margin: -0.3rem 0 0.8rem;

  &:empty {
    display: none;
  }
`;

export const Main = styled(antd.Layout)`
  height: calc(100vh - 2rem);
  padding: 1rem;
  margin-left: 270px;
`;

export const Footer = styled(antd.Layout.Footer)`
  padding: 0.8rem 0 0;
  margin: auto 0 -0.3rem;
  text-align: center;
`;
