import styled from 'styled-components';

export const Wrapper = styled.article`
  display: flex;
  align-items: center;
  padding-top: 0.55rem;
  border-top: 1px solid #eee;

  > * {
    flex: 1;
  }
`;

export const ServiceSelect = styled.div`
  display: flex;
  margin-bottom: 0.5rem;

  > .ant-form-item {
    flex: 1;
    margin-bottom: 0;
    margin-right: 0.3rem;
  }
`;

export const Aside = styled.div`
  flex: 0;
  padding-left: 0.1rem;
  margin-right: -0.4rem;
`;
