import React, { useCallback, useState } from "react";
import { useMutation, useQueryCache } from "react-query";
import { Upload, notification } from "antd";
import { ButtonProps } from "antd/lib/button";
import { Button } from "./UploadButton.styled";
import srv from "../../services";

interface Props {
  url?: string;
  filename?: string;
}

export default function UploadButton({
  children,
  ...restProps
}: Props & ButtonProps) {
  const { isFileUploading, onUploadFile } = useFile(restProps);

  return (
    <Upload
      showUploadList={false}
      action={"/uploadreestr"}
      accept={".xml"}
      customRequest={onUploadFile}
    >
      <Button
        size="middle"
        type="primary"
        loading={isFileUploading}
        {...restProps}
      >
        {children}
      </Button>
    </Upload>
  );
}

function useFile({ url, filename }: Props) {
  const queryCache = useQueryCache();
  const [isFileUploading, setFileUploading] = useState(false);
  const [uploadFile] = useMutation(srv.reestrUploadFile, {
    onSuccess: (data) => {
      queryCache.invalidateQueries("applications");
      setFileUploading(false);
    },
    onError: (data) => {
      setFileUploading(false);
      const message = data || "Произошла ошибка при загрузке файла";

      notification.error({
        message,
        description: filename,
      });
    },
  });

  const onUploadFile = useCallback(
    async (options) => {
      setFileUploading(true);
      //const { onSuccess, onError, file, onProgress } = options;
      const { file } = options;
      console.log(options);

      console.log(file);

      const fmData = new FormData();
      fmData.append("fileData", file);
      console.log(file);

      uploadFile(fmData);
    },
    [uploadFile]
  );

  return { isFileUploading, onUploadFile };
}
