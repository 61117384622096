import React from 'react';
import { Switch, Route, Link } from 'react-router-dom';
import SignIn from './SignIn';
import Registration from './Registration';
import RestorePassword from './RestorePassword';
import RestorePasswordConfirm from './RestorePasswordConfirm';
import JurisdictionSearch from '../PageJurisdiction/JurisdictionSearch';
import { Spin, Wrapper, Header, Tabs, JurisdictionWrapper } from './Auth.styled';

interface Props {
  isLoading: boolean;
}

export default function Auth({ isLoading }: Props) {
  const navigation = [
    { name: 'Войти', render: <SignIn /> },
    { name: 'Регистрация', render: <Registration /> },
    { name: 'Восстановить пароль', render: <RestorePassword /> },
  ];

  if (isLoading) return <Spin size="large" />;

  return (
    <Wrapper>
      <Header>
        <Link to="/">Портал заявителя</Link>
      </Header>

      <Switch>
        <Route path="/restore-password/:id" component={RestorePasswordConfirm} />

        <Route
          path="/jurisdiction-search"
          render={() => (
            <JurisdictionWrapper>
              <h1>Территориальная подсудность</h1>
              <JurisdictionSearch />
            </JurisdictionWrapper>
          )}
        />

        <Route
          render={() => (
            <Tabs
              size="large"
              centered
              animated={false}
              destroyInactiveTabPane
            >
              {navigation.map(navItem => (
                <Tabs.TabPane
                  key={navItem.name}
                  tab={navItem.name}
                >
                  {navItem.render}
                </Tabs.TabPane>
              ))}
            </Tabs>
          )}
        />
      </Switch>
    </Wrapper>
  );
}
