import styled from 'styled-components';
import * as antd from 'antd';

export const Alert = styled(antd.Alert)`
  i {
    display: block;
    font-style: normal;
    font-size: 0.94em;
    margin-top: 0.5rem;
  }
`;
