import styled from 'styled-components';
import * as icons from '@ant-design/icons';

export const RemoveListItem = styled(icons.MinusCircleOutlined)`
  color: #bb0000;
  margin-left: 0.4rem;
`;

export const Address = styled.div`
  display: flex;

  > * {
    flex: 1;
    margin-right: 0.5rem;

    &:last-child {
      margin-right: 0;
      max-width: 7.23rem;
    }
  }
`;

export const PeriodSum = styled.div`
  display: flex;

  > * {
    flex: 1;
    margin-right: 0.33rem;

    &:last-child {
      flex: none;
      margin-left: auto;
      margin-right: 0;
    }
  }
`;
