import axios, { AxiosError } from 'axios';
import moment from 'moment';
import * as auth from './auth';
import * as applicant from './applicant';
import * as applications from './applications';
import * as jurisdiction from './jurisdiction';
import * as logs from './logs';
import * as suggestions from './suggestions';
import * as users from './users';
import queryCache from '../queryCache';
import { prodacthost, testhost, develophost } from '../../package.json';

moment.fn.toJSON = function() { return moment(this).format('YYYY-MM-DDT00:00:00') };

axios.defaults.baseURL = window.location.href.startsWith(prodacthost)
  ? `${prodacthost}/api/back/v1`
  : window.location.href.startsWith(testhost)
    ? `${testhost}/api/back/v1`
  : `${develophost}:7311/api/back/v1`;

axios.interceptors.request.use(async (config) => {
  const token = localStorage.getItem('token');
  if (token) config.headers.Authorization = `Bearer ${token}`;
  return config;
});

axios.interceptors.response.use(res => {
  if (res.config.responseType === 'blob') return res;

  if (res.config.url.includes('geocoder/')) {
    queryCache.setQueryData('geocoderAvailable', res.data?.status === 'ok');
  }

  if (res.data?.status === 'error') {
    return Promise.reject(res.data?.errors?.[0]?.error || 'Произошла неизвестная ошибка');
  }

  return {
    ...res,
    data: res.data?.response ?? null,
    statusText: res.data?.messages?.[0] ?? '',
  };
}, (err: AxiosError) => {
  if (err.isAxiosError && err.config.url.includes('geocoder/')) {
    queryCache.setQueryData('geocoderAvailable', false);
  }

  const token = localStorage.getItem('token');

  if (err.response?.status === 401 && !!token) {
    localStorage.removeItem('token');
    queryCache.setQueryData('user', null);
  }

  return Promise.reject(err.message);
});

export default {
  ...auth,
  ...applicant,
  ...applications,
  ...jurisdiction,
  ...logs,
  ...suggestions,
  ...users,
};
